import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import '../../assets/css/privacy_policy_style.css';
import Header from "./includes/Header";
import Footer from "./includes/Footer";
import parse from 'html-react-parser';
import {Helmet} from "react-helmet";

export default function PrivacyPolicyPage (props) {
    const [headerScroll, setHeaderScroll] = useState(false);
    const [html, setHTML] = useState('');
    const [news_single_info, setNewsSingleInfo] = useState('');
    const [img_path, setImgPath] = useState('https://smus.justcode.am/uploads/');

    const [other_news, setOtherNews] = useState([]);
    const [event_announcements, setEventAnnouncements] = useState([]);
    const [last_news, setLastNews] = useState([]);
    const [dark_mode, setDarkMode] = useState(false);


    useEffect(() => {
        // localStorage.clear();
        getPostsEvents();
        getNewPosts();

        let darkMode = localStorage.getItem('darkMode')
        if (darkMode) {
            setDarkMode(true)
        }

        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }

        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const handleLoad = () => {
    };

    const handleScroll = (ev) => {
        let scrollTop = window.scrollY;

        if (scrollTop > 0) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    };

    const getTime =  (date) => {
        let timestamp = date;
        timestamp = timestamp.substring(0, 10);
        date = new Date(timestamp);

        let options = {timeZone: 'Europe/Moscow',day: 'numeric', month: 'long', year: 'numeric' };
        let formattedDate = date.toLocaleDateString('ru-RU', options);

        // console.log(formattedDate);
        return formattedDate;
    }


    const getPostsEvents = () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://smus.justcode.am/api/new_post_event?event_limit=3&post_limit=3", requestOptions)
            .then(response => response.json())
            .then(result =>
                {
                    console.log(result, 'postsEvents')
                    if (result?.status === true) {
                        setEventAnnouncements(result?.event);
                        setOtherNews(result?.post)
                    }

                }
            )
            .catch(error => console.log('error', error));

    }

    const getNewPosts = () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://smus.justcode.am/api/get_two_new_post", requestOptions)
            .then(response => response.json())
            .then(result =>
                {
                    if (result?.status === true) {
                        setLastNews(result?.data)
                    }
                    console.log(result, 'new_post')
                }

            )
            .catch(error => console.log('error', error));
    }

    const navigate = useNavigate();

    const navigateToNewsSinglePage = (id) => {
        console.log(id, 'id')
        navigate(`/news/${id}`);
    }


    return (
        <>

            <Helmet>
                <title>Политика конфиденциальности</title>
                <meta name="description" content="Политика конфиденциальности" />
            </Helmet>

            <div className='main_wrapper' id='privacy_policy_page'  style={dark_mode ? {backgroundColor: '#2A2C38'} : {}}>
                <Header/>
                <main  style={dark_mode ? {backgroundColor: '#2A2C38'} : {}}>
                    <section className="privacy_policy_page" >
                        <div className="privacy_policy_page_wrapper">
                            <div className="privacy_policy_page_items_main_wrapper"  style={dark_mode ? {borderTop: '1px solid #747474', borderBottom: '1px solid #747474'} : {} }>
                                <div className="privacy_policy_page_items_wrapper">
                                    <div className="privacy_policy_page_item1"  style={dark_mode ? {borderRight: '1px solid #747474'} : {} }>
                                        <div className='privacy_policy_page_links_title_wrapper'>
                                            <Link to={'/'} className='privacy_policy_page_link' style={dark_mode ? {color: '#C6C9CF'} : {}}>Главная</Link>
                                            <p className='events_calendar_link_icon' style={dark_mode ? {color: '#C6C9CF'} : {}}>·</p>
                                            <Link to={'/newsCategory'} className='privacy_policy_page_link' style={dark_mode ? {color: '#C6C9CF'} : {}}>Политика конфиденциальности</Link>
                                        </div>
                                        <h1 className='privacy_policy_page_main_title'  style={dark_mode ? {borderBottom: '1px solid #747474', color: '#ffffff'} : {} }>Политика конфиденциальности</h1>
                                        <div className="privacy_policy_info_items_wrapper">
                                            <div className="privacy_policy_info_item">
                                                <p className="privacy_policy_info_item_title" style={dark_mode ? {color: '#ffffff'} : {}}>1. Общие положения</p>
                                                <p className='privacy_policy_info_item_info' style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. № 152-ФЗ «О персональных данных» (далее — Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые Михайловым Иваном Сергеевичем (далее — Оператор).
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее — Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта httpsː//thismywebsite·com.
                                                </p>
                                            </div>
                                            <div className="privacy_policy_info_item">
                                                <p className="privacy_policy_info_item_title" style={dark_mode ? {color: '#ffffff'} : {}}>
                                                    2. Основные понятия, используемые в Политике
                                                </p>
                                                <p className='privacy_policy_info_item_info' style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    2.1. Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники.
                                                </p>
                                                <p className='privacy_policy_info_item_info' style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    2.2. Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).
                                                </p>
                                                <p className='privacy_policy_info_item_info' style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    2.3. Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу httpsː//thismywebsite·com.
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    2.6. Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    2.7. Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и/или осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
                                                </p>
                                                <p className='privacy_policy_info_item_info' style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    2.8. Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта httpsː//thismywebsite·com.
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    2.9. Персональные данные, разрешенные субъектом персональных данных для распространения, — персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Законом о персональных данных (далее — персональные данные, разрешенные для распространения).
                                                </p>
                                                <p className='privacy_policy_info_item_info' style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    2.10. Пользователь — любой посетитель веб-сайта httpsː//thismywebsite·com.
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    2.11. Предоставление персональных данных — действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    2.12. Распространение персональных данных — любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    2.13. Трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.
                                                </p>
                                                <p className='privacy_policy_info_item_info' style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    2.14. Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и/или уничтожаются материальные носители персональных данных.
                                                </p>
                                            </div>
                                            <div className="privacy_policy_info_item">
                                                <p className="privacy_policy_info_item_title" style={dark_mode ? {color: '#ffffff'} : {}}>
                                                    3. Основные права и обязанности Оператора
                                                </p>
                                                <p className='privacy_policy_info_item_info' style={dark_mode ? {color: '#BBBDCA'} : {}}>3.1. Оператор имеет право:</p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    — получать от субъекта персональных данных достоверные информацию и/или документы, содержащие персональные данные;
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    — в случае отзыва субъектом персональных данных согласия на обработку персональных данных, а также, направления обращения с требованием о прекращении обработки персональных данных, Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в Законе о персональных данных;
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    — самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими федеральными законами.
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    3.2. Оператор обязан: —
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    — предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки его персональных данных;
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    — организовывать обработку персональных данных в порядке, установленном действующим законодательством РФ;
                                                </p>
                                                <p className="privacy_policy_info_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>
                                                    — принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных;
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="privacy_policy_page_item2">
                                        <h2 className="event_announcement_title" style={dark_mode ? {color: '#ffffff'} : {}}>Анонсы событий</h2>
                                        <div className="event_announcements_news_items_wrapper">
                                            {event_announcements.map((item, index) => {
                                                return (
                                                    <button onClick={() => navigateToNewsSinglePage(item?.id)} key={index} className='event_announcements_news_item' style={dark_mode ? {border: '1px solid #747474', backgroundColor: 'unset'} : {}}>
                                                        <div className="event_announcements_news_item_img">
                                                            <img src={img_path + item?.background_photo} alt=""/>
                                                        </div>
                                                        <div className="event_announcements_news_item_info_box">
                                                            <p className="event_announcements_news_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>{item?.title}</p>
                                                            <p className="event_announcements_news_item_date">{getTime(item?.created_at)}</p>
                                                        </div>
                                                    </button>
                                                )
                                            })}
                                        </div>
                                        <div className="last_news_items_wrapper">
                                            <h2 className="last_news_title" style={dark_mode ? {color: '#ffffff'} : {}}>последние новости</h2>
                                            {last_news?.map((item, index) => {
                                                return (
                                                    <button onClick={() => navigateToNewsSinglePage(item?.id)}  key={index} className='last_news_item' style={dark_mode ? {border: '1px solid #747474', backgroundColor: 'unset'} : {}}>
                                                        <div className="last_news_item_img">
                                                            <img src={img_path + item?.photo} alt=""/>
                                                            {/*<div className='last_news_fav_icon'>*/}
                                                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">*/}
                                                            {/*        <path d="M2.37891 7.12597C2.18051 6.94249 2.28828 6.6108 2.55663 6.57899L6.35889 6.12817C6.46825 6.11521 6.56324 6.0463 6.60938 5.9463L8.21313 2.46939C8.32631 2.22401 8.67515 2.22398 8.78833 2.46936L10.3921 5.94635C10.4382 6.04635 10.5326 6.11522 10.6419 6.12819L14.4444 6.57899C14.7127 6.6108 14.8204 6.94249 14.622 7.12597L11.8111 9.72561C11.7302 9.80041 11.6942 9.91187 11.7156 10.0199L12.4617 13.7754C12.5144 14.0404 12.2323 14.2454 11.9965 14.1134L8.65545 12.2432C8.55931 12.1895 8.44227 12.1894 8.3462 12.2432L5.00479 14.1135C4.76899 14.2455 4.48663 14.0405 4.53929 13.7755L5.28553 10.0199C5.307 9.91181 5.27095 9.80041 5.19009 9.72567L2.37891 7.12597Z" fill="white"/>*/}
                                                            {/*    </svg>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                        <div className="last_news_item_info_box">
                                                            <p className="last_news_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>{item?.title}</p>
                                                        </div>
                                                    </button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="privacy_policy_page_other_news_items_wrapper">
                                {other_news?.map((item, index) => {
                                    return (
                                        <button onClick={() => navigateToNewsSinglePage(item?.id)} key={index} className='privacy_policy_page_other_news_item'>
                                            <div className="privacy_policy_page_other_news_item_img">
                                                <img src={img_path + item?.photo} alt=""/>
                                                <div className='privacy_policy_page_other_news_item_img_child'>
                                                    <img src={require('../../assets/images/about_smus_child_img.png')} alt=""/>
                                                </div>
                                            </div>
                                            <div className="privacy_policy_page_other_news_item_info_box">
                                                <p className="privacy_policy_page_other_news_item_title">Что еще почитать</p>
                                                <p className="privacy_policy_page_other_news_item_info">{item?.title}</p>
                                            </div>
                                        </button>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>

        </>

    )


}



