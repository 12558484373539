import React, {useEffect, useState} from 'react';
import Calendar from 'react-calendar';
import '../../../assets/css/calendar_style.css';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";

const RangeCalendar = (props) => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(() => {
        const nextMonth = new Date();
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        return nextMonth;
    });
    const [events, setEvents] = useState([]);
    const [showSelectedDates, setShowSelectedDates] = useState(false);
    const [dark_mode, setDarkMode] = useState(false);
    const [events_dates, setEventsDates] = useState([]);

    useEffect(() => {
        getSpecificEvents();
    }, [])

    useEffect(() => {


        let darkMode = localStorage.getItem('darkMode')
        if (darkMode) {
            setDarkMode(true)
        }

    }, [])

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleAddEvent = () => {
         if (startDate > endDate) {
              alert('Дата начала не может быть больше даты окончания')
             return false
         }
        setEvents([...events, { startDate, endDate }]);
        setShowSelectedDates(true); // Set the flag to show selected dates

        console.log(startDate, endDate, 'finalDates')
        props.handleFilter(startDate, endDate)
    };

    const formatRussianDate = (date) => {
        return date.toLocaleDateString('ru-RU', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    };

    const getSpecificEvents = () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://smus.justcode.am/api/get_events_dates", requestOptions)
            .then(response => response.json())
            .then(result =>
                {
                    console.log(result, 'results dates')
                    if (result?.status === true) {
                        let dates = [];
                        for (let i = 0; i < result?.data.length; i++) {
                            console.log(moment(result?.data[i]).format('Y-MM-DD'), 'moment(result?.data[i]).format(\'Y-MM-DD\')')
                              dates.push(moment(result?.data[i]).format('Y-MM-DD'))
                        }
                        console.log(dates, 'datesmmmfmf')
                        setEventsDates(dates)
                    }


                }
            )
            .catch(error => console.log('error', error));
    }

    return (
        <div className='calendar_main_wrapper_parent'>
            <div className='calendar_main_wrapper'>
                <div className='calendar_child'>
                    <Calendar
                        tileClassName={({ date, view }) => {
                            let formatDate = moment(date).format('Y-MM-DD')
                            console.log(formatDate, 'formatDate')
                            const foundElement = events_dates.find(item => item === formatDate);
                            if (foundElement) {
                                return 'active-day'
                            } else {
                                return 'no-active-day'
                            }

                        }}
                        onChange={handleStartDateChange}
                        value={startDate}
                        locale={'ru'}
                    />
                </div>
                <div className='calendar_child'>
                    <Calendar
                        onChange={handleEndDateChange}
                        value={endDate}
                        locale={'ru'}
                        tileClassName={({ date, view }) => {
                            let formatDate = moment(date).format('Y-MM-DD')
                            console.log(formatDate, 'formatDate')
                            const foundElement = events_dates.find(item => item === formatDate);
                            if (foundElement) {
                                return 'active-day'
                            } else {
                                return 'no-active-day'
                            }

                        }}
                    />
                </div>
            </div>

            {showSelectedDates && (
                <p className='shown_dates' style={dark_mode ? {color: '#ffffff'} : {}}>
                    {formatRussianDate(startDate)} - {formatRussianDate(endDate)}
                </p>
            )}

            <button onClick={handleAddEvent} className='calendar_show_btn'>
                Показать
            </button>
        </div>
    );
};

export default RangeCalendar;
