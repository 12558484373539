import React, { useState, useEffect, useRef } from 'react';
import '../../../assets/css/header_style.css';
import {Link, useNavigate} from "react-router-dom";
import logo from '../../../assets/svg/logo.svg'
import mobileLogo from '../../../assets/svg/mobile_logo.svg'
import searchIcon from '../../../assets/svg/searchIcon.svg'
import personIcon from '../../../assets/svg/person.svg'
import sunIcon from '../../../assets/svg/sun.svg'
import moonIcon from '../../../assets/svg/moon_icon.svg'
import mobileSearchIcon from '../../../assets/svg/mobile_search_icon.svg'
import showSocialLinksIcon from '../../../assets/svg/show_social_links_mobile_icon.svg'
import mobileModeIcon from '../../../assets/svg/mobile_mode_icon.svg'
import hamburgerIcon from '../../../assets/svg/hamburger_icon.svg'
import mobileSearchIcon2 from '../../../assets/svg/searh_icon2.svg'

export default function Header(props) {
    const [headerScroll, setHeaderScroll] = useState(false);
    const [search, setSearch] = useState(props?.input ?? '');
    const [show_dropdown, setShowDropdown] = useState(false);
    const [select_dropdown_list_option, setSelectDropdownListOption] = useState(null);
    const [all_categories, setAllCategories] = useState([]);
    const [show_header_hamburger_menu, setShowHeaderHamburgerMenu] = useState(false);
    const [show_header_search_menu, setShowHeaderSearchMenu] = useState(false);
    const [show_header_social_networks_popup, setShowHeaderSocialNetworksPopup] = useState(false);
    const [dark_mode, setDarkMode] = useState(false);



    useEffect(() => {

        getAllCategories();

        let darkMode = localStorage.getItem('darkMode')
        if (darkMode) {
            setDarkMode(true)
        }

        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }


        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLoad = () => {};

    const handleScroll = (ev) => {
        const scrollTop = window.scrollY;

        if (scrollTop > 0) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    };
    const disableBodyScroll = () => {
        document.body.style.overflow = "hidden";
    };

    const enableBodyScroll = () => {
        document.body.style.overflow = "auto";
    };

    const  pathName = () => {
        let name = window.location.pathname;
        console.log(name, 'nameee')
        return name
    }

    const  selectDropdownOption = (option) => {
        setSelectDropdownListOption(option);
        setShowDropdown(false);
    }

    const  redirectToSearchPage = () => {
        window.location = '/search'
        enableBodyScroll()

    }
    const  redirectToHomePage = () => {
        window.location = '/'
        enableBodyScroll()

    }
    const  redirectToAboutSmusPage = () => {
        window.location = '/about-smus'
        enableBodyScroll()

    }
    const  redirectToPublicationFeedsPage = () => {
        window.location = '/publicationFeeds'
        enableBodyScroll()

    }
    const  redirectToTribunePage = () => {
        window.location = '/tribune'
        enableBodyScroll()

    }
    const  redirectToEventsCalendarPage = () => {
        window.location = '/events'
        enableBodyScroll()

    }
    const turnLightOrDarkMode = () => {
        let darkMode = localStorage.getItem('darkMode');

        if (darkMode) {
            localStorage.removeItem('darkMode');
        } else {
            localStorage.setItem('darkMode', true);
        }
        window.location.reload();

    };
    const myStyle = {
        backgroundColor: '#2A2C38'
    }
    const myStyle2 = {
        color: '#ffffff'
    }
    const getAllCategories = () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://smus.justcode.am/api/get_category", requestOptions)
            .then(response => response.json())
            .then(result =>
                {
                    if (result?.status === true) {
                        setAllCategories(result?.data)
                    }
                    console.log(result, 'allCategories')
                }

            )
            .catch(error => console.log('error', error));
    }

    const checkIsLogin = () => {
         let token = localStorage.getItem('token');
        console.log(token, 'headerToken')
         return token;

    }
    const navigate = useNavigate();
    const navigateToSearch = (search) => {
         setShowHeaderSearchMenu(false)
        enableBodyScroll()
        navigate(`/search/${search}`);
    }


    const navigateToNewsCategoryPage = (id) => {
        console.log(id, 'id')
        navigate(`/category/${id}`);
    }


    return (
        <>

            <div  className='header' style={dark_mode ? myStyle : null}>
                <div className='header_wrapper'>
                    <div className="header_first_item" style={dark_mode ? {borderBottom: '1px solid #747474'} : {}}>
                        <div className="header_first_item_wrapper">
                            <div className='header_social_links_main_wrapper'>
                                <div className="header_social_links_wrapper">
                                    <a href="" className="header_social_link vk_header_social_link">
                                        <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.34457 9.62492C3.39055 9.62492 0.565833 6.23537 0.449951 0.585986H2.9415C3.02116 4.72882 4.84626 6.48159 6.29481 6.84373V0.585986H8.6343V4.1567C10.0611 4.00454 11.5676 2.37492 12.0746 0.578735H14.4067C14.2166 1.50855 13.8367 2.38915 13.2909 3.16553C12.745 3.94192 12.045 4.59732 11.2344 5.09095C12.1389 5.54118 12.9378 6.17809 13.5783 6.95964C14.2187 7.7411 14.6862 8.64953 14.95 9.62492H12.3787C11.8283 7.90839 10.4522 6.57575 8.6343 6.39469V9.62492H8.35182H8.34457Z" fill="#D4E4F2"/>
                                        </svg>

                                    </a>
                                    <a href="" className="header_social_link youtube_header_social_link">
                                        <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.5875 1.5756C14.4062 0.941224 13.9531 0.488098 13.3187 0.306848C12.2312 0.0349733 7.60933 0.0349731 7.60933 0.0349731C7.60933 0.0349731 3.07808 0.0349733 1.89996 0.306848C1.26559 0.488098 0.812451 0.941224 0.631201 1.5756C0.449951 2.75372 0.449951 5.10997 0.449951 5.10997C0.449951 5.10997 0.449951 7.46622 0.721826 8.64435C0.903076 9.27872 1.3562 9.73185 1.99058 9.9131C3.07808 10.185 7.69995 10.185 7.69995 10.185C7.69995 10.185 12.2312 10.185 13.4093 9.9131C14.0437 9.73185 14.4968 9.27872 14.6781 8.64435C14.95 7.46622 14.95 5.10997 14.95 5.10997C14.95 5.10997 14.95 2.75372 14.5875 1.5756ZM6.24995 7.28497V2.93497L10.0562 5.10997L6.24995 7.28497Z" fill="#D4E4F2"/>
                                        </svg>

                                    </a>
                                    <a href="" className="header_social_link telegram_header_social_link">
                                        <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.5405 5.84563C5.43283 4.14985 8.02833 3.03181 9.32698 2.49169C13.0349 0.94943 13.8053 0.681543 14.3076 0.672572C14.4179 0.670714 14.6649 0.698128 14.8249 0.827903C14.96 0.937468 14.9971 1.08555 15.015 1.1895C15.0327 1.29335 15.0548 1.53016 15.0373 1.71512C14.8363 3.82632 13.9669 8.94972 13.5246 11.3143C13.3374 12.3149 12.9688 12.6504 12.612 12.6832C11.8368 12.7545 11.248 12.1708 10.497 11.6785C9.32182 10.9082 8.65799 10.4286 7.51729 9.67698C6.19906 8.80825 7.05365 8.33084 7.80494 7.55056C8.0015 7.34638 11.4178 4.23903 11.4839 3.95709C11.4922 3.92184 11.4998 3.79043 11.4218 3.72102C11.3437 3.65169 11.2285 3.67543 11.1453 3.69428C11.0275 3.72102 9.15017 4.96185 5.51348 7.4167C4.98061 7.78265 4.49794 7.96091 4.06557 7.95157C3.58879 7.94124 2.67177 7.68196 1.99005 7.46038C1.1539 7.1886 0.489341 7.04487 0.547214 6.58332C0.577356 6.34289 0.908446 6.09693 1.5405 5.84563Z" fill="#D4E4F2"/>
                                        </svg>

                                    </a>
                                    <a href="" className="header_social_link dzen_header_social_link">
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.4065 0.609985C14.338 6.57539 13.9479 9.91391 11.7259 12.1359C9.50388 14.358 6.16535 14.7481 0.199951 14.8166V15.6823C6.16535 15.7507 9.50388 16.1408 11.7259 18.3629C13.9131 20.55 14.3253 23.8189 14.4031 29.61H15.2757C15.3534 23.8189 15.7656 20.55 17.9528 18.3629C20.14 16.1757 23.4089 15.7634 29.2 15.6857V14.813C23.4089 14.7354 20.14 14.3231 17.9528 12.1359C15.7308 9.91391 15.3407 6.57539 15.2722 0.609985H14.4065Z" fill="#D4E4F2"/>
                                        </svg>

                                    </a>
                                </div>
                            </div>

                            <div className='header_logo_wrapper'>
                                <Link to={'/'} className='header_logo'>
                                    <img src={logo} alt=""/>
                                </Link>
                            </div>
                            <div className="header_search_input_buttons_wrapper">
                                <div  className='header_search_form'>
                                    <input type="text" placeholder='Что будем искать?' value={search}
                                           className='header_search_form_input_field'
                                           onChange={(e) => {
                                               setSearch(e.target.value)
                                           }}
                                           onKeyPress={(e) => {
                                               if (e.key === 'Enter' && search.length > 0) {
                                                   navigateToSearch(search);
                                               }
                                           }}
                                    />
                                    <button type='button'
                                            onClick={() => {
                                                {search.length > 0 &&
                                                    navigateToSearch(search)
                                                }

                                            }} className='header_search_form_button'>
                                        <img src={searchIcon} alt=""/>
                                    </button>
                                </div>
                                {checkIsLogin()  ?
                                    <Link to={'/personalArea'} className='header_personal_area_btn'>
                                        <img src={personIcon} alt=""/>
                                    </Link>
                                    :
                                    <Link to={'/authorization'} className='header_personal_area_btn'>
                                        <img src={personIcon} alt=""/>
                                    </Link>

                                }

                                <button className='header_mode_btn' onClick={() => turnLightOrDarkMode()}>
                                    {dark_mode ?
                                        <img src={moonIcon} alt=""/>
                                        :
                                        <img src={sunIcon} alt=""/>

                                    }
                                </button>

                            </div>
                        </div>

                    </div>
                    <div className="header_second_item">
                        <div className='headers_navigation_links_wrapper'>
                            <Link to={'/'} className={dark_mode ? 'headers_navigation_link2' : 'headers_navigation_link'}>
                                Главная
                            </Link>
                            <Link to={'/about-smus'}  className={dark_mode ? 'headers_navigation_link2' : 'headers_navigation_link'}>
                                О СМУС ОПК
                            </Link>
                            <div className='header_dropdown_wrapper'>
                                <div className='header_dropdown_title_button_wrapper' onClick={() => {setShowDropdown(!show_dropdown)}}>
                                    <p  className={dark_mode ? 'header_dropdown_title2' : 'header_dropdown_title'}>
                                        {select_dropdown_list_option ? select_dropdown_list_option.name : ' Все рубрики'}
                                    </p>

                                    <button
                                        className='header_dropdown_button'
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                            <path d="M6.67052 9.30001L11.4284 4.54208L10.3098 3.42188L6.67052 7.06355L3.03201 3.42188L1.9126 4.54129L6.67052 9.30001Z" fill="#E71E24"/>
                                        </svg>
                                    </button>
                                    <button
                                        className='mobile_header_dropdown_button'
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path d="M6.0699 3.03984L1.31198 7.79776L2.4306 8.91797L6.0699 5.27629L9.7084 8.91797L10.8278 7.79855L6.0699 3.03984Z" fill="#E71E24"/>
                                        </svg>
                                    </button>




                                </div>
                                {/*{show_dropdown &&*/}
                                <div className="header_dropdown">
                                    <div className='header_dropdown_icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
                                            <path d="M7.3999 2L1.3999 16H13.3999L7.3999 2Z" fill="#2F333A" stroke="#2F333A"/>
                                        </svg>
                                    </div>
                                    {all_categories?.map((option, index) => {
                                        return (
                                            <a
                                                href={`/category/${option?.id}`}
                                                className="header_dropdown_info"
                                                key={index}
                                                // onClick={() => {
                                                //         navigateToNewsCategoryPage(option?.id)
                                                //         enableBodyScroll()
                                                //         selectDropdownOption(option)
                                                //     }
                                                // }
                                            >
                                                {option.name}
                                            </a>
                                        )
                                    })}
                                </div>
                                {/*}*/}

                            </div>

                            <Link to={'/publicationFeeds'}  className={dark_mode ? 'headers_navigation_link2' : 'headers_navigation_link'}>
                                Лента публикаций
                            </Link>
                            <Link to={'/tribune'} className={dark_mode ? 'headers_navigation_link2' : 'headers_navigation_link'}>
                                Трибуна
                            </Link>
                            <Link to={'/events'} className={dark_mode ? 'headers_navigation_link2' : 'headers_navigation_link'}>
                                Календарь событий
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='mobile_header_wrapper'>
                        <div className="mobile_header_first_item">
                            <Link to={'/'} className='mobile_logo_link'>
                                <img src={mobileLogo} alt=""/>
                            </Link>
                        </div>
                        <div className="mobile_header_line" style={dark_mode ? {backgroundColor: '#484B5B'} : {}}></div>
                        <div className='mobile_header_second_item_wrapper' style={dark_mode ? {backgroundColor: '#1D1F22'} : {}}>
                            <div className="mobile_header_second_item">
                                <button
                                    className="mobile_second_item_btn"
                                    onClick={() => {
                                        setShowHeaderSearchMenu(true)
                                        disableBodyScroll()
                                    }}
                                >
                                    <img src={mobileSearchIcon} alt=""/>
                                </button>
                                <button
                                    className='mobile_second_item_btn'
                                    onClick={() => {
                                        setShowHeaderSocialNetworksPopup(true)
                                        disableBodyScroll()
                                    }}
                                >
                                    <img src={showSocialLinksIcon} alt=""/>
                                </button>
                                <button className="mobile_second_item_btn" onClick={() => turnLightOrDarkMode()}>
                                    {dark_mode ?
                                        <img src={moonIcon} alt=""/>
                                        :
                                        <img src={mobileModeIcon} alt=""/>

                                    }

                                </button>
                                {checkIsLogin()  ?
                                    <Link to={'/personalArea'} className='mobile_second_item_btn'>
                                        <img src={personIcon} alt=""/>
                                    </Link>
                                    :
                                    <Link to={'/authorization'} className='mobile_second_item_btn'>
                                        <img src={personIcon} alt=""/>
                                    </Link>

                                }
                                <button
                                    className="mobile_second_item_btn"
                                    onClick={() => {
                                        setShowHeaderHamburgerMenu(true)
                                        disableBodyScroll()
                                    }}
                                >
                                    <img src={hamburgerIcon} alt=""/>
                                </button>
                            </div>

                        </div>
                </div>

                {show_header_hamburger_menu &&
                    <div className="hamburger_menu_popup">
                        <div className="hamburger_menu_popup_wrapper" style={dark_mode ? myStyle : {}}>
                            <button
                                className='hamburger_menu_popup_close_btn'
                                onClick={() => {
                                    setShowHeaderHamburgerMenu(false)
                                    enableBodyScroll()
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                                    <path d="M28.3512 8.41699L19.5003 17.2678L10.6495 8.41699L8.41699 10.6495L17.2678 19.5003L8.41699 28.3512L10.6495 30.5837L19.5003 21.7328L28.3512 30.5837L30.5837 28.3512L21.7328 19.5003L30.5837 10.6495L28.3512 8.41699Z" fill="#E71E24"/>
                                </svg>
                            </button>
                            <div className='headers_navigation_links_wrapper'>
                                <button  className='headers_navigation_link'  style={dark_mode ? myStyle2 : {}} onClick={() => redirectToHomePage()}>
                                    Главная
                                </button>
                                <button  className='headers_navigation_link' style={dark_mode ? myStyle2 : {}} onClick={() => redirectToAboutSmusPage()}>
                                    О СМУС ОПК
                                </button>

                                <button   className='headers_navigation_link'  style={dark_mode ? myStyle2 : {}} onClick={() => redirectToPublicationFeedsPage()}>
                                    Лента публикаций
                                </button>
                                <button  className='headers_navigation_link' style={dark_mode ? myStyle2 : {}}  onClick={() => redirectToTribunePage()}>
                                    Трибуна
                                </button>
                                <button className='headers_navigation_link'  style={dark_mode ? myStyle2 : {}} onClick={() => redirectToEventsCalendarPage()}>
                                    Календарь событий
                                </button>

                                <div className='header_dropdown_wrapper'>
                                    <div className='header_dropdown_title_button_wrapper'  onClick={() => {setShowDropdown(!show_dropdown)}}>
                                        <p className='header_dropdown_title'  style={dark_mode ? myStyle2 : {}}>
                                            {select_dropdown_list_option ? select_dropdown_list_option.name : ' Все рубрики'}
                                        </p>

                                        <button
                                            className='header_dropdown_button'
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                                <path d="M6.67052 9.30001L11.4284 4.54208L10.3098 3.42188L6.67052 7.06355L3.03201 3.42188L1.9126 4.54129L6.67052 9.30001Z" fill="#E71E24"/>
                                            </svg>
                                        </button>
                                        <button
                                            className='mobile_header_dropdown_button'
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                <path d="M6.0699 3.03984L1.31198 7.79776L2.4306 8.91797L6.0699 5.27629L9.7084 8.91797L10.8278 7.79855L6.0699 3.03984Z" fill="#E71E24"/>
                                            </svg>
                                        </button>
                                    </div>
                                    {/*{show_dropdown &&*/}
                                    <div className="header_dropdown" style={dark_mode ? {backgroundColor: '#1D1F22'} : {}}>
                                        {/*<div className='header_dropdown_icon'>*/}
                                        {/*    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">*/}
                                        {/*        <path d="M7.3999 2L1.3999 16H13.3999L7.3999 2Z" fill="#2F333A" stroke="#1D1F22"/>*/}
                                        {/*    </svg>*/}
                                        {/*</div>*/}
                                        {all_categories?.map((option, index) => {
                                            return (
                                                <a
                                                    href={`/category/${option?.id}`}
                                                    className="header_dropdown_info"
                                                    key={index}
                                                    // onClick={() => {
                                                    //         navigateToNewsCategoryPage(option?.id)
                                                    //         enableBodyScroll()
                                                    //         selectDropdownOption(option)
                                                    //     }
                                                    // }
                                                >
                                                    {option.name}
                                                </a>
                                            )
                                        })}
                                    </div>
                                    {/*}*/}

                                </div>

                            </div>

                        </div>
                    </div>
                }
                {show_header_search_menu &&
                    <div className="header_search_popup">
                    <div className="header_search_popup_wrapper">
                        <button
                            className='header_search_popup_close_btn'
                            onClick={() => {
                                setShowHeaderSearchMenu(false)
                                enableBodyScroll()
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                                <path d="M28.3512 8.41699L19.5003 17.2678L10.6495 8.41699L8.41699 10.6495L17.2678 19.5003L8.41699 28.3512L10.6495 30.5837L19.5003 21.7328L28.3512 30.5837L30.5837 28.3512L21.7328 19.5003L30.5837 10.6495L28.3512 8.41699Z" fill="white"/>
                            </svg>
                        </button>

                        <div  className='header_search_form'>
                            <input type="text" placeholder='Что будем искать?' value={search}
                                   className='header_search_form_input_field'
                                   onChange={(e) => {
                                       setSearch(e.target.value)
                                   }}
                                   onKeyPress={(e) => {
                                       if (e.key === 'Enter' && search.length > 0) {
                                           navigateToSearch(search);
                                       }
                                   }}
                            />
                            <button type='button'
                                    onClick={() => {
                                        {search.length > 0 &&
                                        navigateToSearch(search)
                                        }

                                    }} className='header_search_form_button'>
                                <img src={mobileSearchIcon2} alt=""/>
                            </button>
                        </div>

                    </div>
                </div>
                }
                {show_header_social_networks_popup &&
                    <div className="header_social_networks_popup">
                    <div className="header_social_networks_popup_wrapper">
                        <button
                            className='header_social_networks_popup_close_btn'
                            onClick={() => {
                                setShowHeaderSocialNetworksPopup(false)
                                enableBodyScroll()
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                                <path d="M28.3512 8.41699L19.5003 17.2678L10.6495 8.41699L8.41699 10.6495L17.2678 19.5003L8.41699 28.3512L10.6495 30.5837L19.5003 21.7328L28.3512 30.5837L30.5837 28.3512L21.7328 19.5003L30.5837 10.6495L28.3512 8.41699Z" fill="#E71E24"/>
                            </svg>
                        </button>

                        <div className="header_social_links_wrapper">
                            <a href="" className="header_social_link vk_header_social_link">
                                <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.34457 9.62492C3.39055 9.62492 0.565833 6.23537 0.449951 0.585986H2.9415C3.02116 4.72882 4.84626 6.48159 6.29481 6.84373V0.585986H8.6343V4.1567C10.0611 4.00454 11.5676 2.37492 12.0746 0.578735H14.4067C14.2166 1.50855 13.8367 2.38915 13.2909 3.16553C12.745 3.94192 12.045 4.59732 11.2344 5.09095C12.1389 5.54118 12.9378 6.17809 13.5783 6.95964C14.2187 7.7411 14.6862 8.64953 14.95 9.62492H12.3787C11.8283 7.90839 10.4522 6.57575 8.6343 6.39469V9.62492H8.35182H8.34457Z" fill="#D4E4F2"/>
                                </svg>

                            </a>
                            <a href="" className="header_social_link youtube_header_social_link">
                                <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.5875 1.5756C14.4062 0.941224 13.9531 0.488098 13.3187 0.306848C12.2312 0.0349733 7.60933 0.0349731 7.60933 0.0349731C7.60933 0.0349731 3.07808 0.0349733 1.89996 0.306848C1.26559 0.488098 0.812451 0.941224 0.631201 1.5756C0.449951 2.75372 0.449951 5.10997 0.449951 5.10997C0.449951 5.10997 0.449951 7.46622 0.721826 8.64435C0.903076 9.27872 1.3562 9.73185 1.99058 9.9131C3.07808 10.185 7.69995 10.185 7.69995 10.185C7.69995 10.185 12.2312 10.185 13.4093 9.9131C14.0437 9.73185 14.4968 9.27872 14.6781 8.64435C14.95 7.46622 14.95 5.10997 14.95 5.10997C14.95 5.10997 14.95 2.75372 14.5875 1.5756ZM6.24995 7.28497V2.93497L10.0562 5.10997L6.24995 7.28497Z" fill="#D4E4F2"/>
                                </svg>

                            </a>
                            <a href="" className="header_social_link telegram_header_social_link">
                                <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.5405 5.84563C5.43283 4.14985 8.02833 3.03181 9.32698 2.49169C13.0349 0.94943 13.8053 0.681543 14.3076 0.672572C14.4179 0.670714 14.6649 0.698128 14.8249 0.827903C14.96 0.937468 14.9971 1.08555 15.015 1.1895C15.0327 1.29335 15.0548 1.53016 15.0373 1.71512C14.8363 3.82632 13.9669 8.94972 13.5246 11.3143C13.3374 12.3149 12.9688 12.6504 12.612 12.6832C11.8368 12.7545 11.248 12.1708 10.497 11.6785C9.32182 10.9082 8.65799 10.4286 7.51729 9.67698C6.19906 8.80825 7.05365 8.33084 7.80494 7.55056C8.0015 7.34638 11.4178 4.23903 11.4839 3.95709C11.4922 3.92184 11.4998 3.79043 11.4218 3.72102C11.3437 3.65169 11.2285 3.67543 11.1453 3.69428C11.0275 3.72102 9.15017 4.96185 5.51348 7.4167C4.98061 7.78265 4.49794 7.96091 4.06557 7.95157C3.58879 7.94124 2.67177 7.68196 1.99005 7.46038C1.1539 7.1886 0.489341 7.04487 0.547214 6.58332C0.577356 6.34289 0.908446 6.09693 1.5405 5.84563Z" fill="#D4E4F2"/>
                                </svg>

                            </a>
                            <a href="" className="header_social_link dzen_header_social_link">
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.4065 0.609985C14.338 6.57539 13.9479 9.91391 11.7259 12.1359C9.50388 14.358 6.16535 14.7481 0.199951 14.8166V15.6823C6.16535 15.7507 9.50388 16.1408 11.7259 18.3629C13.9131 20.55 14.3253 23.8189 14.4031 29.61H15.2757C15.3534 23.8189 15.7656 20.55 17.9528 18.3629C20.14 16.1757 23.4089 15.7634 29.2 15.6857V14.813C23.4089 14.7354 20.14 14.3231 17.9528 12.1359C15.7308 9.91391 15.3407 6.57539 15.2722 0.609985H14.4065Z" fill="#D4E4F2"/>
                                </svg>

                            </a>
                        </div>

                    </div>
                </div>
                }
            </div>

        </>
    );
}
