import React, { useState, useEffect, useRef } from 'react';
import '../../../assets/css/footer_style.css';
import {Link} from "react-router-dom";
import logo from  '../../../assets/svg/footer_logo.svg'
import vkIcon from "../../../assets/svg/vk.svg";
import youtubeIcon from "../../../assets/svg/youtube.svg";
import telegramIcon from "../../../assets/svg/telegram.svg";
import zenIcon from "../../../assets/svg/zen.svg";
import heartIcon from "../../../assets/svg/heart_icon.svg";



export default function Footer (props) {
    const [headerScroll, setHeaderScroll] = useState(false);

    const [show_categories_dropdown, setShowCategoriesDropdown] = useState(false);
    const [categories_dropdown_list, setCategoriesDropdownList] = useState([
        {
            id: 1,
            label: 'Рубрика (1)',
            value: 'Рубрика (1)',
        },
        {
            id: 2,
            label: 'Рубрика (5)',
            value: 'Рубрика (5)',
        },
        {
            id: 3,
            label: 'Рубрика (2)',
            value: 'Рубрика (2)',
        },
        {
            id: 4,
            label: 'Рубрика (5)',
            value: 'Рубрика (5)',
        },
        {
            id: 5,
            label: 'Рубрика (9)',
            value: 'Рубрика (9)',
        },
        {
            id: 6,
            label: 'Рубрика (10)',
            value: 'Рубрика (10)',
        },
        {
            id: 7,
            label: 'Рубрика (25)',
            value: 'Рубрика (25)',
        },
        {
            id: 8,
            label: 'Рубрика (77)',
            value: 'Рубрика (77)',
        },
        {
            id: 9,
            label: 'Рубрика (98)',
            value: 'Рубрика (98)',
        },
        {
            id: 10,
            label: 'Рубрика (105)',
            value: 'Рубрика (105)',
        }
    ]);
    const [select_category_list_option, setSelectCategoryListOption] = useState(null);

    const [show_archives_dropdown, setShowArchivesDropdown] = useState(false);
    const [archives_dropdown_list, setArchivesDropdownList] = useState([
        {
            id: 1,
            label: 'январь',
            value: 'январь',
        },
        {
            id: 2,
            label: 'февраль',
            value: 'февраль',
        },
        {
            id: 3,
            label: 'март',
            value: 'март',
        },
        {
            id: 4,
            label: 'апрель',
            value: 'апрель',
        },
        {
            id: 5,
            label: 'май',
            value: 'май',
        },
        {
            id: 6,
            label: 'июнь',
            value: 'июнь',
        },
        {
            id: 7,
            label: 'июль',
            value: 'июль',
        },
        {
            id: 8,
            label: 'август',
            value: 'август',
        },
        {
            id: 9,
            label: 'сентябрь',
            value: 'сентябрь',
        },
        {
            id: 10,
            label: 'октябрь',
            value: 'октябрь',
        },
        {
            id: 11,
            label: 'ноябрь',
            value: 'ноябрь',
        },
        {
            id: 12,
            label: 'декабрь',
            value: 'декабрь',
        },
    ]);
    const [select_archives_list_option, setSelectArchivesListOption] = useState(null);
    const [dark_mode, setDarkMode] = useState(false);



    useEffect(() => {
        let darkMode = localStorage.getItem('darkMode')
        if (darkMode) {
            setDarkMode(true)
        }

        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }


        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLoad = () => {};

    const handleScroll = (ev) => {
        const scrollTop = window.scrollY;

        if (scrollTop > 0) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    };
    const disableBodyScroll = () => {
        document.body.style.overflow = "hidden";
    };

    const enableBodyScroll = () => {
        document.body.style.overflow = "auto";
    };

    const  pathName = () => {
        let name = window.location.pathname;
        console.log(name, 'nameee')
        return name
    }

    const selectCategoryOption = (option) => {
        setSelectCategoryListOption(option);
        setShowCategoriesDropdown(false);
    };
    const selectArchivesOption = (option) => {
        setSelectArchivesListOption(option);
        setShowArchivesDropdown(false);
    };

    const myStyle = {
        backgroundColor: '#2A2C38'
    }
    const getCurrentYear = () => {
        let currentYear = new Date().getFullYear();
        return currentYear;
    }

    return (
        <>

            <div  className='footer' style={dark_mode ? myStyle : null}>
                <div className='footer_wrapper'>
                    <div className="footer_first_item">
                        <div className="footer_first_item_child1" style={dark_mode ? {border: '1px solid #747474'} : {}}>
                            <div className='footer_logo_img_wrapper'>
                                <a href="" className='footer_logo'>
                                    <img src={logo} alt=""/>
                                </a>
                                <div className='footer_img'>
                                    <img src={require('../../../assets/images/footer_img.png')} alt=""/>
                                </div>
                                <div className='mobile_footer_img'>
                                    <img src={require('../../../assets/images/mobile_footer_img.png')} alt=""/>
                                </div>
                            </div>

                            <p className='footer_info1' style={dark_mode ? {color: '#ffffff'} : {}}>
                                Разместите описание сайта, для людей или поисковых ботов, или контактную информацию. Блок поддерживает шорткоды.
                            </p>
                            <div className="footer_social_links_wrapper">
                                <a href="" className="footer_social_link vk_header_social_link">
                                    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.34457 9.62492C3.39055 9.62492 0.565833 6.23537 0.449951 0.585986H2.9415C3.02116 4.72882 4.84626 6.48159 6.29481 6.84373V0.585986H8.6343V4.1567C10.0611 4.00454 11.5676 2.37492 12.0746 0.578735H14.4067C14.2166 1.50855 13.8367 2.38915 13.2909 3.16553C12.745 3.94192 12.045 4.59732 11.2344 5.09095C12.1389 5.54118 12.9378 6.17809 13.5783 6.95964C14.2187 7.7411 14.6862 8.64953 14.95 9.62492H12.3787C11.8283 7.90839 10.4522 6.57575 8.6343 6.39469V9.62492H8.35182H8.34457Z" fill="#D4E4F2"/>
                                    </svg>

                                </a>
                                <a href="" className="footer_social_link youtube_footer_social_link">
                                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5875 1.5756C14.4062 0.941224 13.9531 0.488098 13.3187 0.306848C12.2312 0.0349733 7.60933 0.0349731 7.60933 0.0349731C7.60933 0.0349731 3.07808 0.0349733 1.89996 0.306848C1.26559 0.488098 0.812451 0.941224 0.631201 1.5756C0.449951 2.75372 0.449951 5.10997 0.449951 5.10997C0.449951 5.10997 0.449951 7.46622 0.721826 8.64435C0.903076 9.27872 1.3562 9.73185 1.99058 9.9131C3.07808 10.185 7.69995 10.185 7.69995 10.185C7.69995 10.185 12.2312 10.185 13.4093 9.9131C14.0437 9.73185 14.4968 9.27872 14.6781 8.64435C14.95 7.46622 14.95 5.10997 14.95 5.10997C14.95 5.10997 14.95 2.75372 14.5875 1.5756ZM6.24995 7.28497V2.93497L10.0562 5.10997L6.24995 7.28497Z" fill="#D4E4F2"/>
                                    </svg>

                                </a>
                                <a href="" className="footer_social_link telegram_footer_social_link">
                                    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5405 5.84563C5.43283 4.14985 8.02833 3.03181 9.32698 2.49169C13.0349 0.94943 13.8053 0.681543 14.3076 0.672572C14.4179 0.670714 14.6649 0.698128 14.8249 0.827903C14.96 0.937468 14.9971 1.08555 15.015 1.1895C15.0327 1.29335 15.0548 1.53016 15.0373 1.71512C14.8363 3.82632 13.9669 8.94972 13.5246 11.3143C13.3374 12.3149 12.9688 12.6504 12.612 12.6832C11.8368 12.7545 11.248 12.1708 10.497 11.6785C9.32182 10.9082 8.65799 10.4286 7.51729 9.67698C6.19906 8.80825 7.05365 8.33084 7.80494 7.55056C8.0015 7.34638 11.4178 4.23903 11.4839 3.95709C11.4922 3.92184 11.4998 3.79043 11.4218 3.72102C11.3437 3.65169 11.2285 3.67543 11.1453 3.69428C11.0275 3.72102 9.15017 4.96185 5.51348 7.4167C4.98061 7.78265 4.49794 7.96091 4.06557 7.95157C3.58879 7.94124 2.67177 7.68196 1.99005 7.46038C1.1539 7.1886 0.489341 7.04487 0.547214 6.58332C0.577356 6.34289 0.908446 6.09693 1.5405 5.84563Z" fill="#D4E4F2"/>
                                    </svg>

                                </a>
                                <a href="" className="footer_social_link dzen_footer_social_link">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.4065 0.609985C14.338 6.57539 13.9479 9.91391 11.7259 12.1359C9.50388 14.358 6.16535 14.7481 0.199951 14.8166V15.6823C6.16535 15.7507 9.50388 16.1408 11.7259 18.3629C13.9131 20.55 14.3253 23.8189 14.4031 29.61H15.2757C15.3534 23.8189 15.7656 20.55 17.9528 18.3629C20.14 16.1757 23.4089 15.7634 29.2 15.6857V14.813C23.4089 14.7354 20.14 14.3231 17.9528 12.1359C15.7308 9.91391 15.3407 6.57539 15.2722 0.609985H14.4065Z" fill="#D4E4F2"/>
                                    </svg>

                                </a>
                            </div>
                            <div className="footer_line" style={dark_mode ? {border: '1px solid #747474'} : {}}></div>
                            <div className='connect_with_us_wrapper'>
                                <p className='connect_with_us_title' style={dark_mode ? {color: '#ffffff'} : {}}>
                                    Связаться с нами
                                    <span className='connect_with_us_title_icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none"> <path d="M18.9071 8.5572C19.2976 8.16668 19.2976 7.53352 18.9071 7.14299L12.5431 0.77903C12.1526 0.388505 11.5194 0.388505 11.1289 0.77903C10.7384 1.16955 10.7384 1.80272 11.1289 2.19324L16.7857 7.8501L11.1289 13.507C10.7384 13.8975 10.7384 14.5306 11.1289 14.9212C11.5194 15.3117 12.1526 15.3117 12.5431 14.9212L18.9071 8.5572ZM0.199951 8.8501H18.2V6.8501H0.199951V8.8501Z" fill={dark_mode ? '#ffffff' : "#E71E24" } /></svg>
                                    </span>
                                </p>
                                <Link to={'/privacyPolicy'} className='connect_with_us_privacy_policy_link' style={dark_mode ? {color: '#ffffff'} : {}}>
                                    Политика конфиденциальности
                                </Link>
                            </div>
                        </div>
                        {/*<div className="footer_first_item_child2" >*/}
                        {/*    <div className="footer_categories_dropdown-wrapper" style={dark_mode ? {border: '1px solid #747474'} : {}}>*/}
                        {/*        <p className='footer_categories_dropdown_title' style={dark_mode ? {color: '#ffffff'} : {}}>Рубрики</p>*/}
                        {/*        <div className='footer_categories_dropdown_btn'*/}
                        {/*             onClick={() => {*/}
                        {/*                 setShowCategoriesDropdown(!show_categories_dropdown)*/}
                        {/*             }}*/}
                        {/*             style={{*/}
                        {/*                 border: show_categories_dropdown ? '1px solid #E71E24' : ''*/}
                        {/*             }}*/}
                        {/*        >*/}
                        {/*            <p*/}
                        {/*                className='footer_categories_dropdown_btn_text' style={dark_mode ? {color: '#2B2525'} : {}}>*/}
                        {/*                {select_category_list_option ? select_category_list_option.label : 'Выберите рубрику'}*/}
                        {/*            </p>*/}
                        {/*        </div>*/}

                        {/*        {show_categories_dropdown &&*/}
                        {/*            <div className="footer_categories_dropdown">*/}
                        {/*                {categories_dropdown_list.map((option, index) => {*/}
                        {/*                    return (*/}
                        {/*                        <p*/}
                        {/*                            className="footer_categories_dropdown_info"*/}
                        {/*                            key={option.value}*/}
                        {/*                            onClick={() => selectCategoryOption(option)}*/}
                        {/*                        >*/}
                        {/*                            {option.label}*/}
                        {/*                        </p>*/}
                        {/*                    )*/}
                        {/*                })}*/}


                        {/*            </div>*/}
                        {/*        }*/}

                        {/*    </div>*/}
                        {/*    <div className="footer_categories_dropdown-wrapper"  style={dark_mode ? {border: '1px solid #747474'} : {}}>*/}
                        {/*        <p className='footer_categories_dropdown_title'  style={dark_mode ? {color: '#ffffff'} : {}}>Архивы</p>*/}
                        {/*        <div className='footer_categories_dropdown_btn'*/}
                        {/*             onClick={() => {*/}
                        {/*                 setShowArchivesDropdown(!show_archives_dropdown)*/}
                        {/*             }}*/}
                        {/*             style={{*/}
                        {/*                 border: show_archives_dropdown ? '1px solid #E71E24' : ''*/}
                        {/*             }}*/}
                        {/*        >*/}
                        {/*            <p*/}
                        {/*                className='footer_categories_dropdown_btn_text' style={dark_mode ? {color: '#2B2525'} : {}}>*/}
                        {/*                {select_archives_list_option ? select_archives_list_option.label : 'Выберите месяц'}*/}
                        {/*            </p>*/}
                        {/*        </div>*/}

                        {/*        {show_archives_dropdown &&*/}
                        {/*            <div className="footer_categories_dropdown">*/}
                        {/*            {archives_dropdown_list?.map((option, index) => {*/}
                        {/*                return (*/}
                        {/*                    <p*/}
                        {/*                        className="footer_categories_dropdown_info"*/}
                        {/*                        key={option.value}*/}
                        {/*                        onClick={() => selectArchivesOption(option)}*/}
                        {/*                    >*/}
                        {/*                        {option.label}*/}
                        {/*                    </p>*/}
                        {/*                )*/}
                        {/*            })}*/}

                        {/*        </div>*/}
                        {/*        }*/}

                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="footer_second_item">
                        <p className='footer_info2' style={dark_mode ? {color: '#ffffff'} : {}}>Все права защищены © {getCurrentYear()}</p>
                        {/*<p className='footer_info3' style={dark_mode ? {color: '#ffffff'} : {}}>*/}
                        {/*    Разработано с  <span><img src={heartIcon} alt=""/></span>  командой*/}
                        {/*    <a href="" className='company_link' style={dark_mode ? {color: '#ffffff'} : {}}>Sedov.Company</a>*/}
                        {/*</p>*/}
                        <p className='footer_info4' style={dark_mode ? {color: '#ffffff'} : {}}>Новости от СМУС</p>
                    </div>
                    <div className="mobile_footer_second_item">
                        {/*<p className='footer_info3' style={dark_mode ? {color: '#ffffff'} : {}}>*/}
                        {/*    Разработано с  <span><img src={heartIcon} alt=""/></span>  командой*/}
                        {/*    <a href="" className='company_link' style={dark_mode ? {color: '#ffffff'} : {}}>Sedov.Company</a>*/}
                        {/*</p>*/}

                        <p className='footer_info2' style={dark_mode ? {color: '#ffffff'} : {}}>Все права защищены © 2023</p>
                        <p className='footer_info4' style={dark_mode ? {color: '#ffffff'} : {}}>Новости от СМУС</p>
                    </div>
                </div>
            </div>

        </>
    );
}
