import React from 'react'

import {BrowserRouter as Router,  Routes, Route, Navigate } from 'react-router-dom';

// import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Home from './screens/Home'
import PublicationFeeds from './screens/PublicationFeeds'
import NewsCategory from './screens/NewsCategory'
import NewsSingle from './screens/NewsSinglePage'
import TribunaSingle from './screens/TribunaSinglePage'
import PrivacyPolicy from './screens/PrivacyPolicy'
import Search from './screens/Search'
import Tribune from './screens/Tribune'
import Authorization from './screens/Authorization'
import Registration from './screens/Registration'
import PersonalArea from './screens/PersonalArea'
import EventsCalendar from './screens/EventsCalendar'
import EventCalendarSinglePage from './screens/EventCalendarSinglePage'
import NotFoundPage from './screens/404'
import AboutSmus from './screens/AboutSmus'
import ForgetPassword from './screens/ForgetPassword'
import YandexLogin from './screens/YandexLogin'


require('es6-promise').polyfill();
require('isomorphic-fetch');

export default class Main extends React.Component {

    render(){

        return(
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/publicationFeeds" element={<PublicationFeeds />} />
                    <Route path="/category/:id" element={<NewsCategory />} />
                    <Route path="/news/:slug" element={<NewsSingle />} />
                    <Route path="/tribuna/:slug" element={<TribunaSingle />} />
                    <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                    <Route path="/search/:title" element={<Search />} />
                    <Route path="/tribune" element={<Tribune />} />
                    <Route path="/authorization" element={<Authorization />} />
                    <Route path="/forget-password" element={<ForgetPassword />} />
                    <Route path="/registration" element={<Registration />} />
                    <Route path="/personalArea" element={<PersonalArea />} />
                    <Route path="/events" element={<EventsCalendar />} />
                    <Route path="/events-info/:id" element={<EventCalendarSinglePage />} />
                    <Route path="/notFoundPage" element={<NotFoundPage />} />
                    <Route path="/about-smus" element={<AboutSmus />} />
                    <Route path="/yandex-login" element={<YandexLogin />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>

        )
    }
}
