import React, { useState, useEffect } from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import '../../assets/css/calendar_single_page_style.css';
import Header from "./includes/Header";
import Footer from "./includes/Footer";
import Calendar from 'react-calendar';
import parse from 'html-react-parser';
import moment from "moment";
import {Helmet} from "react-helmet";

export default function EventCalendarSinglePage  (props) {
    const [headerScroll, setHeaderScroll] = useState(false);
    const [show_send_reminder_popup, setShowSendReminderPopup] = useState(false);
    const [name, setName] = useState('');
    const [name_error, setNameError] = useState(false);
    const [name_error_text, setNameErrorText] = useState('');

    const [email, setEmail] = useState('');
    const [email_error, setEmailError] = useState(false);
    const [email_error_text, setEmailErrorText] = useState('');
    const [show_subscribe_button, setShowCancelSubscribeButton] = useState(false);

    const [dark_mode, setDarkMode] = useState(false);
    const [events_info, setEventsInfo] = useState([]);
    const [img_path, setImgPath] = useState('https://smus.justcode.am/uploads/');
    const [show_success_popup, setShowSuccessPopup] = useState(false);


    useEffect(() => {
        // localStorage.clear();

        let darkMode = localStorage.getItem('darkMode')
        if (darkMode) {
            setDarkMode(true)
        }
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }

        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const handleLoad = () => {
    };

    const handleScroll = (ev) => {
        let scrollTop = window.scrollY;

        if (scrollTop > 0) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    };

    const disableBodyScroll = () => {
        document.body.style.overflow = "hidden";
    };

    const enableBodyScroll = () => {
        document.body.style.overflow = "auto";
    };

    const { id } = useParams();


    useEffect(() => {
        getEventInfo(id);
        console.log(id, 'newsId')
    }, [id]);


    const getEventInfo = (id) => {

        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`https://smus.justcode.am/api/single_page_event/event_id=${id}`, requestOptions)
            .then(response => response.json())
            .then(result =>
                {
                    console.log(result, 'event_info')
                    if (result?.status === true) {
                        setEventsInfo(result?.data)
                    }
                }

            )
            .catch(error => console.log('error', error));
    }

    const getSubscribe = (id) => {

        if (name.length == 0 || email.length == 0) {
                if (name.length == 0) {
                     setNameError(true)
                     setNameErrorText('Поле является обязательным.')
                } else {
                    setNameError(false)
                    setNameErrorText('')
                }
            if (email.length == 0) {
                setEmailError(true)
                setEmailErrorText('Поле является обязательным.')
            } else {
                setEmailError(false)
                setEmailErrorText('')
            }
        } else  {
            setEmailError(false)
            setEmailErrorText('')
            setNameError(false)
            setNameErrorText('')

            let token = localStorage.getItem('token');
            let AuthStr = 'Bearer '+ token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", AuthStr);

            let formdata = new FormData();
            formdata.append('name', name);
            formdata.append("mail", email);
            formdata.append("event_id", id);

            let requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch('https://smus.justcode.am/api/subscribe', requestOptions)
                .then(response => response.json())
                .then(result =>
                    {
                        console.log(result, 'subscribe')
                        if (result?.status === false) {
                            if (result?.message?.mail[0] == 'The mail field must be a valid email address.') {
                                setEmailError(true)
                                setEmailErrorText('Неправильный адрес электронной почты')
                            } else {
                                setEmailError(false)
                                setEmailErrorText('')
                            }
                        } else {
                            if (result?.message == 'Created') {
                                setShowCancelSubscribeButton(true)
                                setShowSendReminderPopup(false)
                                setShowSuccessPopup(true)
                            }
                        }

                    }

                )
                .catch(error => console.log('error', error));
        }

    }
    const cancelSubscribe = (id) => {
            let token = localStorage.getItem('token');
            let AuthStr = 'Bearer '+ token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", AuthStr);

            let formdata = new FormData();
            formdata.append("mail", email);
            formdata.append("event_id", id);

            let requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch('https://smus.justcode.am/api/cancel_subscribe', requestOptions)
                .then(response => response.json())
                .then(result =>
                    {
                        console.log(result, 'cancel_subscribe')
                        if (result?.status === true) {
                             if (result?.message == 'Deleted') {
                                  setShowCancelSubscribeButton(false)
                             }
                        }

                    }

                )
                .catch(error => console.log('error', error));


    }


    const navigate = useNavigate();

    const dateFormatted = (date) => {
        const formattedDate = moment(date).format('DD.MM.YYYY');
        return formattedDate
    }
    const navigateToPrivacyPolicy = () => {
        setShowSendReminderPopup(false)
        enableBodyScroll()
        navigate('/privacyPolicy');
    }
    return (
        <>
            <Helmet>
                <title>Календарь событий</title>
                <meta name="description" content="Календарь событий" />
            </Helmet>


            <div className='main_wrapper' id='events_calendar_single_page'  style={dark_mode ? {backgroundColor: '#2A2C38'} : {}}>
                <Header/>
                <main style={dark_mode ? {backgroundColor: '#2A2C38'} : {}}>
                    <section className="events_calendar_single_page" style={dark_mode ? {borderTop: '1px solid #747474'} : {} }>
                        <div className="events_calendar_single_page_wrapper">
                            <div className='events_calendar_single_page_title_links_main_wrapper'>
                                <div className="events_calendar_single_page_links_info_wrapper">
                                    <Link to={'/'} className="events_calendar_single_page_link" style={dark_mode ? {color: '#C6C9CF'} : {}}>Главная</Link>
                                    <p className='events_calendar_link_icon' style={dark_mode ? {color: '#C6C9CF'} : {}}>·</p>
                                    <Link to={'/'} className="events_calendar_single_page_link" style={dark_mode ? {color: '#C6C9CF'} : {}}>Календарь событий</Link>
                                    <p className='events_calendar_link_icon'>·</p>
                                    <p className="events_calendar_single_page_link_info" style={dark_mode ? {color: '#C6C9CF'} : {}}>{events_info?.title}</p>
                                </div>
                                <h1 className='events_calendar_single_page_title' style={dark_mode ? {color: '#ffffff'} : {}}>{events_info?.title}</h1>
                            </div>
                            <div className="events_calendar_single_page_img_main_info_wrapper">
                                <div className="events_calendar_single_page_img">
                                    <img src={img_path + events_info?.background_photo} alt=""/>
                                </div>
                                <div className="events_calendar_single_page_img_info_box">
                                    <div className='events_calendar_single_page_img_info_box_wrapper'>
                                        <div className="events_calendar_single_page_img_info_box_child_wrapper">
                                            <div className="events_calendar_single_page_img_info_box_child1">
                                                <div className="events_calendar_single_page_event_logo" style={dark_mode ? {backgroundColor: '#2A2C38'} : {}}>
                                                    <img src={img_path + events_info?.logo} alt=""/>
                                                </div>
                                                {/*{dark_mode ?*/}
                                                {/*    <div className="events_calendar_single_page_event_logo_dark" >*/}
                                                {/*        <img src={require('../../assets/images/dark_mode_event_img.png')} alt=""/>*/}

                                                {/*    </div>*/}
                                                {/*    :*/}
                                                {/*    <div className="events_calendar_single_page_event_logo" >*/}
                                                {/*        <img src={require('../../assets/images/event_logo_img.png')} alt=""/>*/}


                                                {/*    </div>*/}

                                                {/*}*/}



                                                <div className='events_calendar_single_page_event_info_box'>
                                                    <p className='events_calendar_single_page_img_info_box_child1_title'>{events_info?.name}</p>
                                                    <p className='events_calendar_single_page_img_info_box_child1_info1'>
                                                        {events_info?.short_description}
                                                    </p>
                                                    <p className='events_calendar_single_page_img_info_box_child1_info2'>
                                                        {events_info?.author_description}
                                                    </p>
                                                </div>

                                            </div>
                                            <div className="events_calendar_single_page_img_info_box_child2">
                                                <p className='events_calendar_single_page_img_info_box_child2_info'>{dateFormatted(events_info?.date_start)} - {dateFormatted(events_info?.date_end)}</p>

                                            </div>
                                        </div>
                                        {show_subscribe_button   ?
                                            <button className='send_a_reminder_event_single_btn'
                                                    onClick={() => {
                                                         cancelSubscribe(events_info?.id)
                                                    }}
                                            >
                                                Отменить подписку
                                            </button>
                                            :
                                            <button className='send_a_reminder_event_single_btn'
                                            onClick={() => {
                                            setShowSendReminderPopup(true)
                                            disableBodyScroll()
                                        }}
                                            >
                                            Прислать напоминание
                                            </button>
                                        }

                                    </div>

                                </div>
                            </div>
                            <div className="events_calendar_single_page_details_main_wrapper" style={dark_mode ? {backgroundColor: '#171923'} : {}}>
                                <div className="events_calendar_single_page_details_items_wrapper">
                                    <div className="events_calendar_single_page_details_item">
                                        <p className="events_calendar_single_page_details_item_title" style={dark_mode ? {color: '#ffffff'} : {}}>Сроки проведения</p>
                                        <p className="events_calendar_single_page_details_item_title2" style={dark_mode ? {color: 'rgba(198, 201, 207, 0.85)'} : {}}>Даты проведения:</p>
                                        <div className="events_calendar_single_page_details_item_child">
                                            <p className="events_calendar_single_page_details_item_child_title" style={dark_mode ? {color: 'rgba(198, 201, 207, 0.75)'} : {}}>{dateFormatted(events_info?.date_start)} - {dateFormatted(events_info?.date_end)}</p>
                                            <p className="events_calendar_single_page_details_item_child_info" style={dark_mode ? {color: 'rgba(198, 201, 207, 0.60)'} : {}}>с {events_info?.start_time_live} до {events_info?.end_time_live}</p>
                                        </div>
                                        <div className="events_calendar_single_page_details_item_child">
                                            <p className="events_calendar_single_page_details_item_child_title" style={dark_mode ? {color: 'rgba(198, 201, 207, 0.75)'} : {}}>{events_info?.date_live}</p>
                                            <p className="events_calendar_single_page_details_item_child_info" style={dark_mode ? {color: 'rgba(198, 201, 207, 0.60)'} : {}}>с {events_info?.start_time_live} до {events_info?.end_time_live}</p>
                                        </div>

                                    </div>
                                    <div className="events_calendar_single_page_details_item">
                                        <p className="events_calendar_single_page_details_item_title" style={dark_mode ? {color: '#ffffff'} : {}}>Монтаж:</p>
                                        <p className="events_calendar_single_page_details_item_info" style={dark_mode ? {color: 'rgba(198, 201, 207, 0.75)'} : {}}>{events_info?.montaj}</p>
                                    </div>
                                    <div className="events_calendar_single_page_details_item">
                                        <p className="events_calendar_single_page_details_item_title" style={dark_mode ? {color: '#ffffff'} : {}}>Демонтаж:</p>
                                        <p className="events_calendar_single_page_details_item_info" style={dark_mode ? {color: 'rgba(198, 201, 207, 0.75)'} : {}}>{events_info?.demontaj}</p>
                                    </div>
                                    <div className="events_calendar_single_page_details_item">
                                        <p className="events_calendar_single_page_details_item_title" style={dark_mode ? {color: '#ffffff'} : {}}>Место проведения</p>
                                        <div className='events_calendar_single_page_details_item_info_icon_wrapper'>
                                            <span className="events_calendar_single_page_details_item_info_icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none"><path d="M6.00014 4.52941C5.05728 4.52941 4.28585 5.27059 4.28585 6.17647C4.28585 7.08235 5.05728 7.82353 6.00014 7.82353C6.943 7.82353 7.71443 7.08235 7.71443 6.17647C7.71443 5.27059 6.943 4.52941 6.00014 4.52941ZM6.00014 8.64706C4.62871 8.64706 3.42871 7.57647 3.42871 6.17647C3.42871 4.77647 4.543 3.70588 6.00014 3.70588C7.45728 3.70588 8.57157 4.77647 8.57157 6.17647C8.57157 7.57647 7.37157 8.64706 6.00014 8.64706Z" fill={dark_mode ? '#ffffff' : "#2F333A"}/><path d="M6 0.741176C3.17143 0.741176 0.857143 3.04706 0.857143 5.84706C0.857143 6.58824 1.02857 7.24706 1.28571 7.90588C1.54286 8.4 1.88571 8.89412 2.22857 9.30588L6 13.0118L9.77143 9.30588C10.2 8.89412 10.4571 8.4 10.7143 7.90588C10.9714 7.24706 11.1429 6.58824 11.1429 5.84706C11.2286 3.04706 8.91429 0.741176 6 0.741176ZM6 14C5.91429 14 5.82857 14 5.74286 13.9176L1.71429 9.88235C1.2 9.38824 0.857143 8.81176 0.6 8.23529C0.171429 7.49412 0 6.67059 0 5.84706C0 2.63529 2.65714 0 6 0C9.34286 0 12 2.63529 12 5.84706C12 6.67059 11.8286 7.49412 11.4857 8.23529C11.2286 8.81176 10.8857 9.38824 10.3714 9.88235L6.25714 13.9176C6.25714 13.9176 6.17143 14 6 14Z" fill={dark_mode ? '#ffffff' : "#2F333A"}/></svg>
                                            </span>
                                            <p className='events_calendar_single_page_details_item_info' style={dark_mode ? {color: 'rgba(198, 201, 207, 0.75)'} : {}}>
                                                {events_info?.address}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="numbered_list_main_wrapper" style={dark_mode ? {borderBottom: '1px solid #747474'} : {}}>
                                <div className="numbered_list_wrapper">
                                    <div  className={dark_mode ? 'parsedHtml_event_active' : "parsedHtml_event" } dangerouslySetInnerHTML={{ __html: events_info?.long_description }}/>
                                </div>

                            </div>
                            <div className="russian_organizers_contacts_main_wrapper" style={dark_mode ? {borderBottom: '1px solid #747474'} : {}}>
                                <div className="russian_organizers_contacts_wrapper">
                                    <div className="russian_organizers_wrapper">
                                        <p className="russian_organizers_contacts_title" style={dark_mode ? {color: '#ffffff'} : {}}>Организаторы Российской экспозиции</p>
                                        <div className="russian_organizers_items_wrapper">
                                            {events_info?.event_org?.map((item, index) => {
                                                return (
                                                    <div className='russian_organizers_item' key={index}>
                                                        <p className='russian_organizers_item_title' style={dark_mode ? {color: 'rgba(198, 201, 207, 0.75)'} : {}}>{item?.org?.name}</p>
                                                        <p className="russian_organizers_item_address_info" style={dark_mode ? {color: '#C6C9CF'} : {}}>{item?.org?.address}</p>

                                                        <div className="russian_organizers_item_phone_numbers">
                                                            <a href='tel:+7 (495) 937–4747' className="russian_organizers_item_phone_number" style={dark_mode ? {color: '#C6C9CF'} : {}}>{item?.org?.phone_one}</a>
                                                            <a href='tel:+7 (495) 937–4747' className="russian_organizers_item_phone_number" style={dark_mode ? {color: '#C6C9CF'} : {}}>{item?.org?.phone_two}</a>
                                                        </div>
                                                        <p className='russian_organizers_item_fax' style={dark_mode ? {color: '#C6C9CF'} : {}}>
                                                            <span>Факс:</span>
                                                            {item?.org?.faks}
                                                        </p>

                                                        <p className='russian_organizers_item_hour_info' style={dark_mode ? {color: '#C6C9CF'} : {}}>{item?.org?.work_time}</p>
                                                        <p className="russian_organizers_item_email">
                                                            <span style={dark_mode ? {color: '#C6C9CF'} : {}}>E-mail</span>
                                                            <a href="mailto:info@exportcenter.ru" className='russian_organizers_item_email_link'>{item?.org?.email}</a>
                                                        </p>
                                                        <a href='http://www.exportcenter.ru' className="russian_organizers_item_website">{item?.org?.site}</a>
                                                    </div>
                                                )
                                            })}


                                        </div>
                                    </div>
                                    <div className="russian_contacts_wrapper">
                                        <p className='russian_contacts_title' style={dark_mode ? {color: '#ffffff'} : {}}>Контакты</p>
                                        <div className="russian_contacts_items_wrapper">
                                            {events_info?.event_org?.map((item, index) => {
                                                return (
                                                    <div className="russian_contacts_item" key={index}>
                                                        <p className='russian_contacts_item_name' style={dark_mode ? {color: '#C6C9CF'} : {}}>{item?.name}</p>
                                                        <div className='russian_contacts_item_number_title_box'>
                                                            <p className='russian_contacts_item_numbers_title' style={dark_mode ? {color: '#C6C9CF'} : {}}>Тел.:</p>
                                                            <a href="tel:+7 (499) 795-29-56" className='russian_contacts_item_number_link' style={dark_mode ? {color: '#C6C9CF'} : {}}>{item?.phone}</a>

                                                        </div>
                                                        <div className='russian_contacts_item_email_title_box'>
                                                            <p className='russian_contacts_item_email_title' style={dark_mode ? {color: '#C6C9CF'} : {}}>E-mail</p>
                                                            <a href="mailto:kochetova@expocentr.ru" className='russian_contacts_item_email_link'>{item?.email}</a>
                                                        </div>
                                                    </div>
                                                )
                                            })}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {show_send_reminder_popup &&
                        <div className="show_send_reminder_popup">
                            <div className="show_send_reminder_popup_wrapper">
                                <div className='show_send_reminder_popup_wrapper_child'>
                                    <div className='show_send_reminder_popup_title_close_btn_wrapper' style={dark_mode ? {borderBottom: '1px solid #747474'} : {}}>
                                        <div className='show_send_reminder_popup_title_first_item'>
                                            <p className='show_send_reminder_popup_title1'>Прислать напоминание</p>
                                            <p className='show_send_reminder_popup_title2'>
                                                {events_info?.title}
                                            </p>
                                        </div>
                                        <button
                                            className='show_send_reminder_popup_title_close_btn'
                                            onClick={() => {
                                                enableBodyScroll()
                                                setShowSendReminderPopup(false)
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                                                <path d="M28.3512 8.41675L19.5003 17.2676L10.6495 8.41675L8.41699 10.6492L17.2678 19.5001L8.41699 28.3509L10.6495 30.5834L19.5003 21.7326L28.3512 30.5834L30.5837 28.3509L21.7328 19.5001L30.5837 10.6492L28.3512 8.41675Z" fill="white"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="show_send_reminder_popup_form_img_wrapper">
                                        <form action="" className="show_send_reminder_popup_form">
                                            <div className="show_send_reminder_popup_form_input_parent">
                                                <input type="text" placeholder='Имя' className={dark_mode ? 'show_send_reminder_popup_form_input_field_dark' : 'show_send_reminder_popup_form_input_field'} value={name}
                                                       onChange={(e) => {
                                                           setName(e.target.value)
                                                       }}
                                                />

                                                {name_error &&
                                                    <p className='error_text3'>{name_error_text}</p>
                                                }

                                            </div>
                                            <div className="show_send_reminder_popup_form_input_parent">
                                                <input type="text" placeholder='E-mail'  className={dark_mode ? 'show_send_reminder_popup_form_input_field_dark' : 'show_send_reminder_popup_form_input_field'} value={email}
                                                       onChange={(e) => {
                                                           setEmail(e.target.value)
                                                       }}
                                                />
                                                {email_error &&
                                                    <p className='error_text3'>{email_error_text}</p>
                                                }
                                            </div>
                                            <p className='show_send_reminder_popup_info'>
                                                Нажимая кнопку "Отправить", я подтверждаю, что ознакомлен
                                                и согласен с условиями <button onClick={() => navigateToPrivacyPolicy()} className='show_send_reminder_popup_info_link'>политики обработки персональных данных</button>
                                            </p>
                                            <button type='button' className='show_send_reminder_popup_form_btn' onClick={() => getSubscribe(events_info?.id)}>
                                                Напомнить
                                            </button>
                                        </form>
                                        <div className="show_send_reminder_popup_form_img">
                                            <img src={img_path + events_info?.logo} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {show_success_popup &&
                         <div className="show_send_reminder_popup">
                        <div className="show_send_reminder_popup_wrapper">
                            <div className='show_send_reminder_popup_wrapper_child'>
                                <div className='show_send_reminder_popup_title_close_btn_wrapper show_send_reminder_popup_title_close_btn_wrapper2' style={dark_mode ? {borderBottom: '1px solid #747474'} : {}}>
                                    <button
                                        className='show_send_reminder_popup_title_close_btn'
                                        onClick={() => {
                                            enableBodyScroll()
                                            setShowSuccessPopup(false)
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                                            <path d="M28.3512 8.41675L19.5003 17.2676L10.6495 8.41675L8.41699 10.6492L17.2678 19.5001L8.41699 28.3509L10.6495 30.5834L19.5003 21.7326L28.3512 30.5834L30.5837 28.3509L21.7328 19.5001L30.5837 10.6492L28.3512 8.41675Z" fill="white"/>
                                        </svg>
                                    </button>
                                </div>
                                <div className="show_send_reminder_popup_form_img_wrapper">
                                    <p className='show_send_reminder_popup_title1'>Заявка успешно отправленна</p>
                                    <div className="show_send_reminder_popup_form_img">
                                        <img src={img_path + events_info?.logo} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </main>
                <Footer/>
            </div>

        </>

    )


}



