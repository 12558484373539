import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/authorization_style.css';
import Header from "./includes/Header";
import Footer from "./includes/Footer";
import parse from 'html-react-parser';
import {Helmet} from "react-helmet";

export default function Authorization (props) {
    const [headerScroll, setHeaderScroll] = useState(false);

    const [login, setLogin] = useState('');
    const [login_error, setLoginError] = useState(false);
    const [login_error_text, setLoginErrorText] = useState('');

    const [password, setPassword] = useState('');
    const [password_error, setPasswordError] = useState(false);
    const [password_error_text, setPasswordErrorText] = useState('');

    const [is_active, setIsActive] = useState(false);
    const [is_active2, setIsActive2] = useState(false);
    const [dark_mode, setDarkMode] = useState(false);
    //
    // useEffect(() => {
    //     // Function to disable autocomplete
    //     const disableAutocomplete = () => {
    //         const inputFields = document.querySelectorAll('input');
    //         inputFields.forEach((input) => {
    //             input.setAttribute('autocomplete', 'off');
    //         });
    //     };
    //
    //     // Call the function after a delay of 3000 milliseconds (3 seconds)
    //     disableAutocomplete();
    //
    //     // Optionally, you can clean up the effect when the component unmounts
    //     return () => {
    //         // Clear the timeout to prevent unnecessary execution of disableAutocomplete
    //
    //         // Cleanup code, if needed
    //     };
    // }, []);


    useEffect(() => {


        let darkMode = localStorage.getItem('darkMode')
        if (darkMode) {
            setDarkMode(true)
        }

        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }

        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // Clear email and password fields on component mount
        setLogin('');
        setPassword('');

        // Set values after the component mounts
        const emailInput = document.getElementById('email');
        if (emailInput) {
            emailInput.value = '';
        }

        const passwordInput = document.getElementById('password');
        if (passwordInput) {
            passwordInput.value = '';
        }
    }, []);



    const handleLoad = () => {
    };

    const handleScroll = (ev) => {
        let scrollTop = window.scrollY;

        if (scrollTop > 0) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    };
    const handleFocusIn = () => {
        setIsActive(true);

    };

    const handleFocusOut = () => {

        if (login.length > 0) {
            setIsActive(true);
        } else {
            setIsActive(false);

        }
    };

    const handleFocusIn2 = () => {
        setIsActive2(true);
    };

    const handleFocusOut2 = () => {
        if (password.length > 0) {
            setIsActive2(true);
        } else {
            setIsActive2(false);

        }
    };

    const redirectToPersonalArea = () => {
        window.location = '/personalArea'
    }

    const loginToProfile = () => {
        if (login.length == 0 || password.length == 0) {
             if (login.length == 0) {
                  setLoginError(true)
                  setLoginErrorText('Поле является обязательным.')
             } else  {
                 setLoginError(false)
                 setLoginErrorText('')
             }
            if (password.length == 0) {
                setPasswordError(true)
                setPasswordErrorText('Поле является обязательным.')
            } else  {
                setPasswordError(false)
                setPasswordErrorText('')
            }

        } else {
            setLoginError(false)
            setLoginErrorText('')
            setPasswordError(false)
            setPasswordErrorText('')

            let formdata = new FormData();
            formdata.append("email", login);
            formdata.append("password", password);

            let requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch("https://smus.justcode.am/api/login", requestOptions)
                .then(response => response.json())
                .then(result =>
                    {
                        if (result?.status === false) {
                               if (result?.message == 'wrong email or password') {
                                    setLoginError(true)
                                    setLoginErrorText('Неправильный адрес электронной почты или пароль!')
                               } else {
                                   setLoginError(false)
                                   setLoginErrorText('')
                               }
                        } else {
                            setLoginError(false)
                            setLoginErrorText('')
                            setPasswordError(false)
                            setPasswordErrorText('')
                            localStorage.setItem('token', result.token)
                            window.location = '/personalArea'
                        }



                        console.log(result, 'login')
                    }
                )
                .catch(error => console.log('error', error));
        }

    }


    return (
        <>
            <Helmet>
                <title>Авторизация</title>
                <meta name="description" content="Авторизация" />
            </Helmet>

            <div className='main_wrapper' id='authorization_page' style={dark_mode ? {backgroundColor: '#2A2C38'} : {}}>
                <Header/>
                <main style={dark_mode ? {backgroundColor: '#2A2C38'} : {}}>
                    <section className='authorization'>
                        <div className='authorization_wrapper'>
                            <h1 className='authorization_main_title' style={dark_mode ? {color: '#ffffff'} : {}}>Авторизация</h1>
                            <form action="" className="authorization_form" autoComplete="off">
                                <div className={`${is_active ? 'authorization_form_inputs_main_wrapper_active' : 'authorization_form_inputs_main_wrapper'}`} style={dark_mode ? {border: '1px solid #747474', backgroundColor: 'unset'} : {}}>
                                    <div  className={`${is_active ? 'active' : 'authorization_form_input_box_child'}`}>
                                        <input
                                            type="text"
                                            name='login'
                                            value={login}
                                            className="authorization_form_input_field"
                                               onChange={(e) => {
                                                   setLogin(e.target.value)
                                               }}
                                           onFocus={handleFocusIn}
                                           onBlur={handleFocusOut}
                                            style={dark_mode ? {color: '#ffffff'} : {}}
                                            autoComplete="new-password"
                                            id='email'


                                        />
                                        <label className='authorization_form_input_field_title' style={dark_mode ? {backgroundColor: '#2A2C38', color: '#C6C9CF'} : {}}>Логин</label>
                                    </div>

                                    <span className='authorization_form_input_field_icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none"><path d="M16.5132 3.28006C12.9136 3.28006 9.9873 6.14732 9.9873 9.67419C9.9873 13.1338 12.7488 15.9337 16.3483 16.0549C16.4582 16.0414 16.5681 16.0414 16.6505 16.0549C16.678 16.0549 16.6918 16.0549 16.7192 16.0549C16.733 16.0549 16.733 16.0549 16.7467 16.0549C20.2638 15.9337 23.0253 13.1338 23.039 9.67419C23.039 6.14732 20.1127 3.28006 16.5132 3.28006Z" fill="#E71E24"/><path d="M23.4911 19.6354C19.658 17.1316 13.4069 17.1316 9.54635 19.6354C7.80155 20.7796 6.83984 22.3277 6.83984 23.9834C6.83984 25.6392 7.80155 27.1738 9.53261 28.3045C11.456 29.5699 13.9839 30.2026 16.5118 30.2026C19.0397 30.2026 21.5676 29.5699 23.4911 28.3045C25.2221 27.1603 26.1838 25.6257 26.1838 23.9565C26.1701 22.3008 25.2221 20.7662 23.4911 19.6354Z" fill="#E71E24"/></svg>
                                    </span>
                                </div>
                                {login_error &&
                                    <p className='error_text'>{login_error_text}</p>
                                }
                                <div className={`${is_active2 ? 'authorization_form_inputs_main_wrapper_active' : 'authorization_form_inputs_main_wrapper'}`} style={dark_mode ? {border: '1px solid #747474', backgroundColor: 'unset'} : {}}>
                                    <div  className={`${is_active2 ? 'active2' : 'authorization_form_input_box_child'}`}>
                                        <input
                                            type="password"
                                            name='password'
                                            value={password}
                                            className="authorization_form_input_field"
                                               onChange={(e) => {
                                                   setPassword(e.target.value)
                                               }}
                                           onFocus={handleFocusIn2}
                                           onBlur={handleFocusOut2}
                                            style={dark_mode ? {color: '#ffffff'} : {}}
                                            autoComplete="new-password"
                                            id='password'
                                        />
                                        <label className='authorization_form_input_field_title2' style={dark_mode ? {backgroundColor: '#2A2C38', color: '#C6C9CF'} : {}}>Пароль</label>
                                    </div>

                                    <span className='authorization_form_input_field_icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" viewBox="0 0 27 30" fill="none"><g clip-path="url(#clip0_16_1726)"><path d="M5.10938 12.8945C6.12952 12.6711 7.35549 12.4453 8.73296 12.2726V10.0625C8.73296 7.48763 10.8709 5.39282 13.4988 5.39282C16.1267 5.39282 18.2647 7.48763 18.2647 10.0625V12.2726C19.6422 12.4453 20.8681 12.6711 21.8883 12.8945V10.0625C21.8883 5.52996 18.1248 1.84242 13.4988 1.84242C8.87287 1.84242 5.10938 5.52996 5.10938 10.0626V12.8945Z" fill={dark_mode ? '#7A7C7E' : "#2F333A" } /><path d="M13.4995 14.4989C8.52758 14.4989 4.38583 15.6031 2.9668 16.0313V27.7444C4.38799 28.1724 8.53871 29.2773 13.4995 29.2773C18.4714 29.2773 22.6131 28.1731 24.0321 27.7449V16.0317C22.611 15.6038 18.4603 14.4989 13.4995 14.4989ZM14.2905 22.6856V25.2292H12.7085V22.6856C11.6774 22.3579 10.9295 21.4081 10.9295 20.29C10.9295 18.9015 12.0824 17.7719 13.4995 17.7719C14.9166 17.7719 16.0694 18.9015 16.0694 20.29C16.0695 21.4081 15.3217 22.3579 14.2905 22.6856Z" fill= {dark_mode ? '#7A7C7E' :  "#A2A4A6" }/></g><defs><clipPath id="clip0_16_1726"><rect y="0.86261" width="27" height="28.4147" rx="6" fill= {dark_mode ? '#7A7C7E' :  "white" }/></clipPath></defs></svg>
                                    </span>
                                </div>
                                {password_error &&
                                    <p className='error_text'>{password_error_text}</p>
                                }
                                <button type='button' className='authorization_form_enter_btn'
                                        onClick={() => {
                                            loginToProfile()
                                        }}
                                >
                                    Войти
                                </button>
                            </form>
                            <Link to={'/registration'} className='register_link'  style={dark_mode ? {color: '#CCC'} : {}}>Зарегистрироваться</Link>
                            <Link to={'/forget-password'} className='forget_password_link'  style={dark_mode ? {color: '#CCC'} : {}}>Забыли пароль</Link>
                            <div className="login_with_social_links_items_main_wrapper">
                                <p className="login_with_social_links_item_main_title"  style={dark_mode ? {color: '#ffffff'} : {}}>Войти с помощью</p>
                                <div className='login_with_social_links_items_wrapper'>
                                    <a href="https://vk.com/" className="login_with_social_links_item">
                                    <span className="login_with_social_links_item_icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" viewBox="0 0 38 24" fill="none"><path d="M20.6449 23.8535C7.68986 23.8535 0.303039 14.9895 0 0.216043H6.51557C6.72389 11.0498 11.4966 15.6334 15.2847 16.5805V0.216043H21.4026V9.5537C25.1338 9.15579 29.0733 4.89423 30.3992 0.197083H36.4979C36.0007 2.6286 35.0073 4.93144 33.5799 6.96173C32.1525 8.99203 30.3217 10.7059 28.2021 11.9968C30.5675 13.1742 32.6565 14.8398 34.3314 16.8836C36.0062 18.9271 37.2288 21.3027 37.9184 23.8535H31.1946C29.7551 19.3646 26.1566 15.8797 21.4026 15.4062V23.8535H20.6639H20.6449Z" fill="white"/></svg>
                                    </span>
                                        <span className="login_with_social_links_item_title">Вконтакте</span>
                                    </a>
                                    {/*<a href="https://web.telegram.org/k/" className="login_with_social_links_item">*/}
                                    {/*<span className="login_with_social_links_item_icon">*/}
                                    {/*    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="30" viewBox="0 0 36 30" fill="none"><path d="M2.47481 12.9149C12.1385 8.68137 18.5825 5.89014 21.8068 4.5417C31.0126 0.691403 32.9254 0.0226139 34.1723 0.000215266C34.4464 -0.00442283 35.0595 0.0640175 35.4568 0.388005C35.7921 0.661539 35.8843 1.03123 35.9287 1.29074C35.9728 1.55002 36.0277 2.1412 35.984 2.60298C35.4852 7.87366 33.3265 20.6644 32.2283 26.5677C31.7637 29.0657 30.8486 29.9032 29.9628 29.9851C28.0379 30.1632 26.5761 28.7059 24.7115 27.477C21.7939 25.5538 20.1458 24.3565 17.3137 22.48C14.0409 20.3112 16.1626 19.1193 18.0279 17.1713C18.5159 16.6616 26.9977 8.904 27.1618 8.20014C27.1825 8.11213 27.2014 7.78407 27.0076 7.61076C26.8137 7.43768 26.5277 7.49696 26.3212 7.54402C26.0287 7.61076 21.3678 10.7086 12.3388 16.8372C11.0158 17.7508 9.81742 18.1958 8.74394 18.1725C7.56022 18.1467 5.28349 17.4994 3.59095 16.9462C1.51498 16.2677 -0.134962 15.9089 0.00872274 14.7566C0.0835577 14.1564 0.905573 13.5423 2.47481 12.9149Z" fill="white"/></svg>*/}
                                    {/*</span>*/}
                                    {/*    <span className="login_with_social_links_item_title">Telegram</span>*/}
                                    {/*</a>*/}
                                    <a href="https://ya.ru/" className="login_with_social_links_item">
                                    <span className="login_with_social_links_item_icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none"><path d="M28.7174 2.625H23.4674C18.2174 2.625 12.8755 6.5625 12.8755 15.2513C12.7658 17.2652 13.2153 19.2702 14.1742 21.0446C15.1332 22.819 16.5644 24.2935 18.3093 25.305L11.8124 36.9206C11.6635 37.1731 11.585 37.4609 11.585 37.7541C11.585 38.0472 11.6635 38.335 11.8124 38.5875C11.9471 38.8187 12.1381 39.0121 12.3675 39.1498C12.597 39.2875 12.8575 39.3649 13.1249 39.375H16.393C16.7246 39.3911 17.0532 39.3055 17.3348 39.1295C17.6163 38.9536 17.8373 38.6958 17.968 38.3906L23.9924 26.5781H24.4387V37.8919C24.4402 38.0941 24.4826 38.2939 24.5635 38.4792C24.6443 38.6645 24.7619 38.8315 24.9091 38.9701C25.0563 39.1087 25.2301 39.2161 25.4199 39.2857C25.6098 39.3553 25.8118 39.3856 26.0137 39.375H28.8749C29.2605 39.3893 29.6363 39.2515 29.9211 38.9912C30.2059 38.7309 30.377 38.3691 30.3974 37.9837V4.22625C30.4027 4.02112 30.3674 3.81698 30.2937 3.62548C30.22 3.43397 30.1093 3.25887 29.968 3.11016C29.8266 2.96146 29.6573 2.84207 29.4697 2.75883C29.2822 2.67558 29.08 2.63011 28.8749 2.625H28.7174ZM24.5437 21.3675H23.7693C20.7505 21.3675 18.9655 18.9131 18.9655 14.805C18.9655 9.68625 21.2362 7.86187 23.3493 7.86187H24.583L24.5437 21.3675Z" fill="white"/></svg>
                                    </span>
                                        <span className="login_with_social_links_item_title">Yandex</span>
                                    </a>
                                    <a href="https://mail.ru/" className="login_with_social_links_item">
                                    <span className="login_with_social_links_item_icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none"><path d="M23.4141 18C23.4141 20.9854 20.9854 23.4141 18 23.4141C15.0146 23.4141 12.5859 20.9854 12.5859 18C12.5859 15.0146 15.0146 12.5859 18 12.5859C20.9854 12.5859 23.4141 15.0146 23.4141 18ZM18 0C8.07429 0 0 8.07429 0 18C0 27.9257 8.07429 36 18 36C21.636 36 25.1421 34.9174 28.1391 32.8693L28.1906 32.8333L25.7657 30.015L25.7246 30.0407C23.418 31.5257 20.7463 32.31 18 32.31C10.1096 32.31 3.69 25.8904 3.69 18C3.69 10.1096 10.1096 3.69 18 3.69C25.8904 3.69 32.31 10.1096 32.31 18C32.31 19.0221 32.1956 20.0571 31.9731 21.0754C31.5206 22.9333 30.2194 23.5016 29.2436 23.427C28.2613 23.3473 27.1119 22.6479 27.1041 20.9353V19.6303V18C27.1041 12.9793 23.0207 8.89586 18 8.89586C12.9793 8.89586 8.89586 12.9793 8.89586 18C8.89586 23.0207 12.9793 27.1041 18 27.1041C20.439 27.1041 22.7263 26.1514 24.4504 24.417C25.4533 25.9779 27.0874 26.9563 28.9479 27.1054C29.1073 27.1183 29.2706 27.1247 29.4313 27.1247C30.7414 27.1247 32.0387 26.6863 33.0853 25.893C34.164 25.0727 34.9701 23.8886 35.415 22.4653C35.4857 22.2351 35.6169 21.7093 35.6169 21.7054L35.6207 21.6861C35.883 20.5444 36 19.4066 36 18C36 8.07429 27.9257 0 18 0Z" fill="#FF9E00"/></svg>
                                    </span>
                                        <span className="login_with_social_links_item_title">Mail.ru</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>


        </>

    )


}



