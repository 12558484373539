import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/not_found_style.css';
import logo from '../../assets/svg/logo.svg'


export default function NotFoundPage  (props) {
    const [headerScroll, setHeaderScroll] = useState(false);
    const [dark_mode, setDarkMode] = useState(false);



    useEffect(() => {
        // localStorage.clear();

        let darkMode = localStorage.getItem('darkMode')
        if (darkMode) {
            setDarkMode(true)
        }

        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }

        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const handleLoad = () => {
    };

    const handleScroll = (ev) => {
        let scrollTop = window.scrollY;

        if (scrollTop > 0) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    };




    return (
        <>


            <div className='main_wrapper' id='not_found_page' style={dark_mode ? {backgroundColor: '#2A2C38'} : {}}>
                <main>
                    <section className="not_found_page" style={dark_mode ? {backgroundColor: '#2A2C38'} : {}}>
                        <div className="not_found_page_wrapper">
                            <div className="not_found_page_img">
                                <img src={logo} alt=""/>
                            </div>
                            <h1 className='not_found_page_title'>
                                4<span style={dark_mode ? {color: '#ffffff'} : {}}>0</span>4
                            </h1>
                            <p className="not_found_page_info" style={dark_mode ? {color: '#C6C9CF'} : {}}>
                                Дорогой посетитель, вы попали на 404 страницу. Это значит, что
                                документ, который раньше открывался по этому адресу, более не
                                существует, либо был перемещен. Также есть вероятность, что вы
                                использовали неправильную ссылку.
                            </p>
                            <Link to={'/'} className='not_found_page_link' style={dark_mode ? {color: '#C6C9CF'} : {}}>На Главную</Link>
                            <div className='not_found_page_info_wrapper'>
                                <p className='not_found_page_info2' style={dark_mode ? {color: '#C6C9CF'} : {}}>Все права защищены © 2023</p>
                                <p className="not_found_page_info2" style={dark_mode ? {color: '#C6C9CF'} : {}}>Новости от СМУС</p>
                            </div>
                        </div>
                    </section>

                </main>
            </div>

        </>

    )


}



