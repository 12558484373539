import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/css/about_smus_style.css';
import Header from "./includes/Header";
import Footer from "./includes/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/swiper-bundle.min.css';
import "swiper/css/pagination";
import parse from 'html-react-parser';
import {Helmet} from "react-helmet";

export default function AboutSmus (props) {
    const [headerScroll, setHeaderScroll] = useState(false);
    const [html, setHTML] = useState('');
    const [img_path, setImgPath] = useState('https://smus.justcode.am/uploads/');
    const [event_announcements, setEventAnnouncements] = useState([]);
    const [other_news, setOtherNews] = useState([]);
    const [smus_info, setSmusInfo] = useState({});
    const [last_news, setLastNews] = useState([]);
    const [dark_mode, setDarkMode] = useState(false);
    const [commands_info, setCommandsInfo] = useState([]);
    const [show_hidden_command, setHiddenCommand] = useState([]);
    const [active_hidden_command, setActiveCommand] = useState(false);
    const [active_command_id, setActiveCommandId] = useState('');


    useEffect(() => {
        showCommandMembers(commands_info[0]?.id)
        console.log(commands_info, 'info')
    }, [commands_info])

    useEffect(() => {
        // localStorage.clear();
        getSmusInfo();
        getCommands();
        getPostsEvents();
        getNewPosts();

        let darkMode = localStorage.getItem('darkMode')
        if (darkMode) {
            setDarkMode(true)
        }

        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }

        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const handleLoad = () => {
    };

    const handleScroll = (ev) => {
        let scrollTop = window.scrollY;

        if (scrollTop > 0) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    };


    const getSmusInfo = () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://smus.justcode.am/api/get_smus_info", requestOptions)
            .then(response => response.json())
            .then(result =>
                {
                    if (result?.status === true) {
                        console.log(result)
                        setSmusInfo(result?.data)
                    }
                }
            )
            .catch(error => console.log('error', error));
    }


    const getCommands = () => {

        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://smus.justcode.am/api/get_command", requestOptions)
            .then(response => response.json())
            .then(result =>
                {
                    if (result?.status === true) {
                         setCommandsInfo(result?.data)
                    }
                    console.log(result)
                }

            )
            .catch(error => console.log('error', error));
    }
    const showCommandMembers = (id) => {
        console.log(id, 'activeId')
        for (let i = 0; i < commands_info.length ; i++) {
              if (id == commands_info[i].id) {
                   setHiddenCommand(commands_info[i])
                   setActiveCommand(true);
                   setActiveCommandId(id)
                   break;
              }
        }

    }

    const getPostsEvents = () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://smus.justcode.am/api/new_post_event?event_limit=3&post_limit=3", requestOptions)
            .then(response => response.json())
            .then(result =>
                {
                    console.log(result, 'postsEvents')
                    if (result?.status === true) {
                        setEventAnnouncements(result?.event);
                        setOtherNews(result?.post)
                    }

                }
            )
            .catch(error => console.log('error', error));

    }

    const getTime =  (date) => {
        let timestamp = date;
        timestamp = timestamp.substring(0, 10);
        date = new Date(timestamp);

        let options = {timeZone: 'Europe/Moscow',day: 'numeric', month: 'long', year: 'numeric' };
        let formattedDate = date.toLocaleDateString('ru-RU', options);

        // console.log(formattedDate);
        return formattedDate;
    }


    const getNewPosts = () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://smus.justcode.am/api/get_two_new_post", requestOptions)
            .then(response => response.json())
            .then(result =>
                {
                    if (result?.status === true) {
                         setLastNews(result?.data)
                    }
                    console.log(result, 'new_post')
                }

            )
            .catch(error => console.log('error', error));
    }

    const navigate = useNavigate();

    const navigateToNewsSinglePage = (slug) => {
        console.log(slug, 'id')
        navigate(`/news/${slug}`);
    }
    const navigateToEventPage = (id) => {
        console.log(id, 'id')
        navigate(`/events-info/${id}`);
    }
    return (
        <>

            <Helmet>
                <title>О СМУС ОПК</title>
                <meta name="description" content="О СМУС ОПК" />
            </Helmet>

            <div className='main_wrapper' id='about_smus_page'  style={dark_mode ? {backgroundColor: '#2A2C38'} : {}}>
                <Header/>
                <main  style={dark_mode ? {backgroundColor: '#2A2C38'} : {}}>
                    <section className="about_smus" >
                        <div className="about_smus_page_wrapper">
                            <div className="about_smus_items_main_wrapper">
                                <div className="about_smus_items_wrapper">
                                    <div className='about_smus_item1'>
                                        <div className='about_smus_page_links_title_wrapper'>
                                            <Link to={'/'} className='about_smus_page_link' style={dark_mode ? {color: '#C6C9CF'} : {}}>Главная</Link>
                                            <p className='events_calendar_link_icon' style={dark_mode ? {color: '#C6C9CF'} : {}}>·</p>
                                            <Link to={'/newsCategory'} className='about_smus_page_link' style={dark_mode ? {color: '#C6C9CF'} : {}}>О СМУС ОПК</Link>
                                        </div>
                                        <div  className={dark_mode ? 'parsedHtml1_active' : "parsedHtml1" } dangerouslySetInnerHTML={{ __html: smus_info.one_description }}/>
                                        <div  className={dark_mode ? 'parsedHtml1_active' : "parsedHtml1" } dangerouslySetInnerHTML={{ __html: smus_info.two_description }}/>
                                        <div className='smus_command'>
                                            <p className='smus_command_title' style={dark_mode ? {color: '#ffffff'} : {}}>Познакомьтесь с командой</p>
                                            <div className="smus_commands_tab_items_wrapper">
                                                {commands_info?.map((item,index) => {
                                                    return (
                                                        <button className='smus_commands_tab_item' onClick={() => showCommandMembers(item.id)} key={index} style={active_hidden_command && active_command_id === item.id ? {backgroundColor: '#E71E24', fontWeight: '700', color: "#ffffff", border: '1px solid #E71E24'} : dark_mode ? {color: '#ffffff'} : {}}>
                                                            {item?.name}
                                                        </button>
                                                    )
                                                })}
                                            </div>
                                            <div className='smus_commands_members_items_wrapper'>
                                                {show_hidden_command?.command?.map((item,index) => {
                                                    return (
                                                        <div className='smus_commands_members_item' key={index}>
                                                            <div className="smus_commands_members_item_avatar">
                                                                <img src={img_path + item?.avatar} alt=""/>
                                                                <div className="smus_commands_members_item_avatar_icon">
                                                                    <img src={img_path + item?.left_logo} alt=""/>
                                                                </div>
                                                            </div>
                                                            <p className='smus_commands_members_item_name' style={dark_mode ? {color: '#ffffff'} : {}}>{item?.name}</p>
                                                            <p className='smus_commands_members_item_text1' style={dark_mode ? {color: '#ffffff'} : {}}>{item?.text_one}</p>
                                                            <p className='smus_commands_members_item_text2'>{item?.text_two}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className='mobile_smus_commands_members_items_wrapper_parent'>
                                                <Swiper
                                                    grabCursor={true}
                                                    slidesPerView={1}
                                                    modules={[Autoplay, Pagination, Navigation]}
                                                    loop={true}
                                                    loopedSlides={1}
                                                    pagination={{ clickable: true }}
                                                    className="top_slider"
                                                >
                                                    {show_hidden_command?.command?.map((item, index) => (
                                                        <SwiperSlide key={index}>
                                                            <div className='smus_commands_members_item'>
                                                                <div className="smus_commands_members_item_avatar">
                                                                    <img src={img_path + item?.avatar} alt=""/>
                                                                    <div className="smus_commands_members_item_avatar_icon">
                                                                        <img src={img_path + item?.left_logo} alt=""/>
                                                                    </div>
                                                                </div>
                                                                <p className='smus_commands_members_item_name' style={dark_mode ? {color: '#ffffff'} : {}}>{item?.name}</p>
                                                                <p className='smus_commands_members_item_text1' style={dark_mode ? {color: '#ffffff'} : {}}>{item?.text_one}</p>
                                                                <p className='smus_commands_members_item_text2'>{item?.text_two}</p>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>

                                            </div>

                                        </div>
                                        <div className='about_smus_page_contacts'>

                                            <p className='about_smus_page_contacts_title' style={dark_mode ? {color: '#ffffff'} : {}}>Контакты</p>
                                            <div className="about_smus_page_contacts_items_wrapper">
                                                <div className="about_smus_page_contacts_item">
                                                    <p className="about_smus_page_contacts_item_name" style={dark_mode ? {color: '#ffffff'} : {}}>{smus_info?.contact_one_name}</p>
                                                    <p className="about_smus_page_contacts_item_phone" style={dark_mode ? {color: '#ffffff'} : {}}><span>Тел.:</span>  {smus_info?.contact_one_phone}</p>
                                                    <p className="about_smus_page_contacts_item_email"><span style={dark_mode ? {color: '#C6C9CF'} : {}}>E-mail</span> {smus_info?.contact_one_email}</p>
                                                </div>
                                                <div className="about_smus_page_contacts_item">
                                                    <p className="about_smus_page_contacts_item_name" style={dark_mode ? {color: '#ffffff'} : {}}>{smus_info?.contact_two_name}</p>
                                                    <p className="about_smus_page_contacts_item_phone" style={dark_mode ? {color: '#C6C9CF'} : {}}><span>Тел.:</span>  {smus_info?.contact_two_phone}</p>
                                                    <p className="about_smus_page_contacts_item_email"><span style={dark_mode ? {color: '#C6C9CF'} : {}}>E-mail</span> {smus_info?.contact_two_email}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div  className='map' dangerouslySetInnerHTML={{ __html: smus_info.map_frame }} />

                                    </div>
                                    <div className="about_smus_page_item2">
                                        <p className="event_announcement_title" style={dark_mode ? {color: '#ffffff'} : {}}>Анонсы событий</p>
                                        <div className="event_announcements_news_items_wrapper">
                                            {event_announcements?.map((item, index) => {
                                                return (
                                                    <button onClick={() => navigateToEventPage(item?.id)}  key={index} className='event_announcements_news_item' style={dark_mode ? {border: '1px solid #747474', backgroundColor: 'unset'} : {}}>
                                                        <div className="event_announcements_news_item_img">
                                                            <img src={img_path + item?.background_photo} alt=""/>
                                                        </div>
                                                        <div className="event_announcements_news_item_info_box">
                                                            <p className="event_announcements_news_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>{item?.title}</p>
                                                            <p className="event_announcements_news_item_date">{getTime(item?.created_at)}</p>
                                                        </div>
                                                    </button>
                                                )
                                            })}
                                        </div>
                                        <div className="last_news_items_wrapper">
                                            <p className="last_news_title" style={dark_mode ? {color: '#ffffff'} : {}}>последние новости</p>
                                            {last_news?.map((item, index) => {
                                                return (
                                                    <button onClick={() => navigateToNewsSinglePage(item?.slug)}  key={index} className='last_news_item' style={dark_mode ? {border: '1px solid #747474', backgroundColor: 'unset'} : {}}>
                                                        <div className="last_news_item_img">
                                                            <img src={img_path + item?.photo} alt=""/>
                                                            {/*<div className='last_news_fav_icon'>*/}
                                                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">*/}
                                                            {/*        <path d="M2.37891 7.12597C2.18051 6.94249 2.28828 6.6108 2.55663 6.57899L6.35889 6.12817C6.46825 6.11521 6.56324 6.0463 6.60938 5.9463L8.21313 2.46939C8.32631 2.22401 8.67515 2.22398 8.78833 2.46936L10.3921 5.94635C10.4382 6.04635 10.5326 6.11522 10.6419 6.12819L14.4444 6.57899C14.7127 6.6108 14.8204 6.94249 14.622 7.12597L11.8111 9.72561C11.7302 9.80041 11.6942 9.91187 11.7156 10.0199L12.4617 13.7754C12.5144 14.0404 12.2323 14.2454 11.9965 14.1134L8.65545 12.2432C8.55931 12.1895 8.44227 12.1894 8.3462 12.2432L5.00479 14.1135C4.76899 14.2455 4.48663 14.0405 4.53929 13.7755L5.28553 10.0199C5.307 9.91181 5.27095 9.80041 5.19009 9.72567L2.37891 7.12597Z" fill="white"/>*/}
                                                            {/*    </svg>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                        <div className="last_news_item_info_box">
                                                            <p className="last_news_item_info" style={dark_mode ? {color: '#BBBDCA'} : {}}>{item?.title}</p>
                                                        </div>
                                                    </button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about_smus_page_other_news_items_wrapper">
                                {other_news?.map((item, index) => {
                                    return (
                                        <button onClick={() => navigateToNewsSinglePage(item?.slug)}  key={index} className='about_smus_page_other_news_item'>
                                            <div className="about_smus_page_other_news_item_img">
                                                <img src={img_path + item?.photo} alt=""/>
                                                <div className='about_smus_page_other_news_item_img_child'>
                                                    <img src={require('../../assets/images/about_smus_child_img.png')} alt=""/>
                                                </div>
                                            </div>
                                            <div className="about_smus_page_other_news_item_info_box">
                                                <p className="about_smus_page_other_news_item_title">Что еще почитать</p>
                                                <p className="about_smus_page_other_news_item_info">{item?.title}</p>
                                            </div>
                                        </button>
                                    )
                                })}
                            </div>

                        </div>
                    </section>
                </main>
                <Footer/>
            </div>

        </>

    )


}



