import React, { useState, useEffect, useRef } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import '../../assets/css/calendar_style.css';
import Header from "./includes/Header";
import Footer from "./includes/Footer";
import Calendar from "./includes/Calendar";
import MobileCalendar from "./includes/CalendarMobile";
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import parse from 'html-react-parser';
import moment from 'moment';
import {Helmet} from "react-helmet";




export default function EventCalendar  (props) {
    const [selectedDates, setSelectedDates] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    const [headerScroll, setHeaderScroll] = useState(false);
    const [date, setDate] = useState(new Date());

    const [show_all_events_dropdown, setShowAllEventsDropdown] = useState(false);
    const [event_categories_list, setEventCategoriesList] = useState([]);
    const [select_all_events_list_option, setSelectAllEventsListOption] = useState(null);

    const [show_all_organizers_dropdown, setShowAllOrganizersDropdown] = useState(false);
    const [all_organizers_dropdown_list, setAllOrganizersDropdownList] = useState([]);
    const [select_all_organizers_list_option, setSelectAllOrganizersListOption] = useState(null);
    const [event_search_input, setEventSearchInput] = useState('');

    const [events_list, setEventsList] = useState([]);

    const [dark_mode, setDarkMode] = useState(false);
    const [page_url, setPageUrl] = useState('https://smus.justcode.am/api/get_events?page=1');
    const [img_path, setImgPath] = useState('https://smus.justcode.am/uploads/');
    const [show_send_reminder_popup, setShowSendReminderPopup] = useState(false);
    const [nextDate, setNextDate] = useState('');

    const [name, setName] = useState('');
    const [name_error, setNameError] = useState(false);
    const [name_error_text, setNameErrorText] = useState('');

    const [email, setEmail] = useState('');
    const [email_error, setEmailError] = useState(false);
    const [email_error_text, setEmailErrorText] = useState('');
    const [show_subscribe_button, setShowCancelSubscribeButton] = useState(false);
    const [eventId, setEventId] = useState(/* initial event ID */);
    const [subscribedEvents, setSubscribedEvents] = useState([]);
    const [event_title, setTitle] = useState('');
    const [show_success_popup, setShowSuccessPopup] = useState(false);
    const [popup_img, setPopupImg] = useState('');
    const [events_total_count, setEventsTotalCount] = useState('');

    const dropdownRef = useRef(null);
    const dropdownRef2 = useRef(null);

    const handleOutsideClick = (event) => {
        if (show_all_events_dropdown && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowAllEventsDropdown(false);
        }

        if (show_all_organizers_dropdown && dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setShowAllOrganizersDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [show_all_events_dropdown, show_all_organizers_dropdown]);

    const toggleEventsDropdown = () => {
        setShowAllEventsDropdown((prev) => !prev);
    };

    const toggleOrganizersDropdown = () => {
        setShowAllOrganizersDropdown((prev) => !prev);
    };



    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Attach event listener for window resize
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // localStorage.clear();
        getEventCategories();
        getOrganisations();



        let darkMode = localStorage.getItem('darkMode')
        if (darkMode) {
            setDarkMode(true)
        }


        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }

        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    ;

    useEffect(() => {
        // Use the updated state values within the getEvents function
        getEvents();
    }, []);

    const handleLoad = () => {
    };

    const handleScroll = (ev) => {
        let scrollTop = window.scrollY;

        if (scrollTop > 0) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    };

    const onChange = (newDate) => {
        setDate(newDate);
    };
    const disableBodyScroll = () => {
        document.body.style.overflow = "hidden";
    };

    const enableBodyScroll = () => {
        document.body.style.overflow = "auto";
    };

    const selectAllEventsOption = (option) => {
        setSelectAllEventsListOption(option);
        setShowAllEventsDropdown(false);
        console.log(option, 'selected option')
    };
    const selectAllOrganizersOption = (option) => {
        setSelectAllOrganizersListOption(option);
        setShowAllOrganizersDropdown(false);
    };

    const getEventCategories = () => {

        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://smus.justcode.am/api/get_event_category", requestOptions)
            .then(response => response.json())
            .then(result =>
                {
                    console.log(result, 'categories_event')
                    if (result?.status === true) {
                        setEventCategoriesList(result?.data)
                    }
                }
            )
            .catch(error => console.log('error', error));
    }

    const getOrganisations = () => {

        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://smus.justcode.am/api/get_organizication", requestOptions)
            .then(response => response.json())
            .then(result =>
                {
                    console.log(result, 'organisations')
                    if (result?.status === true) {
                        setAllOrganizersDropdownList(result?.data)
                    }
                }
            )
            .catch(error => console.log('error', error));
    }


    const getCurrentDate = () =>  {
       return new Date();
    }

    const getNextDate = () =>  {
        const currentDate = new Date(nextDate);
       // return  currentDate.setMonth(currentDate.getMonth() + 1);
       return currentDate
    }

    const getStartDate = (date) => {
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        return formatDate(firstDayOfMonth);
    }

    const getEndDate = (date) => {
        const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
         return  formatDate(lastDayOfMonth);

    }

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };



    const getEvents = (start_date_filter = null, end_date_filter = null, ) => {

        let date_ = events_list.length == 0 ? getCurrentDate() : getNextDate();
        let start_date = getStartDate(date_);
        let end_date = getEndDate(date_);

        if (start_date_filter && end_date_filter) {
            start_date = start_date_filter;
            end_date = end_date_filter;
        }

        let url = `https://smus.justcode.am/api/get_event_new_api?start_date=${start_date}&end_date=${end_date}`;
        let params = [];

        if (select_all_events_list_option) {
             params.push(`category_id=${select_all_events_list_option.id}`)
        }

        if (select_all_organizers_list_option) {
            params.push(`organization_id=${select_all_organizers_list_option.id}`)
        }

        if (event_search_input) {
            params.push(`search=${event_search_input}`)
        }

        let stringParams = params.join('&');
        if (params.length > 0) {
             url = `${url}&${stringParams}`
        }
        console.log(stringParams, 'paramssss')

        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        console.log(url, 'https://smus.justcode.am/api/get_event_new_api?start_date=${start_date}&end_date=${end_date}')

        fetch(url, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(result => {


                console.log(result, 'events');
                if (result?.status === true && result?.data) {
                    setEventsTotalCount(result?.count)

                    if ((events_list.length === 0) || (start_date_filter && end_date_filter)) {
                        let events = Object.values(result.data).flat();
                        let sortedEvents = events.sort((a, b) => new Date(a.date_start) - new Date(b.date_start));
                        setEventsList(sortedEvents);
                        console.log(sortedEvents, 'sortedEvents')
                    } else {
                        let events = Object.values(result.data).flat();
                        let combinedArrayConcat = events_list.concat(events);
                        let sortedEvents = combinedArrayConcat.sort((a, b) => new Date(a.date_start) - new Date(b.date_start));

                        console.log(sortedEvents, 'combinedArrayConcat')
                        setEventsList(sortedEvents);
                    }

                    setNextDate(result?.next_date);

                    console.log(page_url, 'page_url')
                }
            })
            .catch(error => console.error('Error fetching events:', error));


    };

    const getMonthName = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('ru-RU', { month: 'long' }).format(date);
    };

    const navigate = useNavigate();

    const navigateToEventsSinglePage = (id) => {
        console.log(id, 'id')
        navigate(`/events-info/${id}`);
    }


    const openReminderPopup = (id, title, logo) => {
        disableBodyScroll();
        setEventId(id);
        setTitle(title)
        setPopupImg(logo)
        setShowSendReminderPopup(true)
    }
    const getSubscribe = () => {

        if (name.length == 0 || email.length == 0) {
            if (name.length == 0) {
                setNameError(true)
                setNameErrorText('Поле является обязательным.')
            } else {
                setNameError(false)
                setNameErrorText('')
            }
            if (email.length == 0) {
                setEmailError(true)
                setEmailErrorText('Поле является обязательным.')
            } else {
                setEmailError(false)
                setEmailErrorText('')
            }
        } else  {
            setEmailError(false)
            setEmailErrorText('')
            setNameError(false)
            setNameErrorText('')

            let token = localStorage.getItem('token');
            let AuthStr = 'Bearer '+ token;

            let myHeaders = new Headers();
            myHeaders.append("Authorization", AuthStr);

            let formdata = new FormData();
            formdata.append('name', name);
            formdata.append("mail", email);
            formdata.append("event_id", eventId);

            let requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch('https://smus.justcode.am/api/subscribe', requestOptions)
                .then(response => response.json())
                .then(result =>
                    {
                        console.log(result, 'subscribe')
                        if (result?.status === false) {
                            if (result?.message?.mail[0] == 'The mail field must be a valid email address.') {
                                setEmailError(true)
                                setEmailErrorText('Неправильный адрес электронной почты')
                            } else {
                                setEmailError(false)
                                setEmailErrorText('')
                            }
                        } else {
                            if (result?.message == 'Created') {
                                // setShowCancelSubscribeButton(true)
                                setSubscribedEvents([...subscribedEvents, eventId]);
                                setShowSendReminderPopup(false)
                                setEmail('')
                                setName('')
                                setShowSuccessPopup(true)
                            }
                        }

                    }

                )
                .catch(error => console.log('error', error));
        }

    }
    const cancelSubscribe = (id) => {
        let token = localStorage.getItem('token');
        let AuthStr = 'Bearer '+ token;

        let myHeaders = new Headers();
        myHeaders.append("Authorization", AuthStr);

        let formdata = new FormData();
        formdata.append("mail", email);
        formdata.append("event_id", id);

        let requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch('https://smus.justcode.am/api/cancel_subscribe', requestOptions)
            .then(response => response.json())
            .then(result =>
                {
                    console.log(result, 'cancel_subscribe')
                    if (result?.status === true) {
                        if (result?.message == 'Deleted') {
                            // setShowCancelSubscribeButton(false)
                            setSubscribedEvents(subscribedEvents.filter(eventId => eventId !== id));
                        }
                    }

                }

            )
            .catch(error => console.log('error', error));


    }


    // const onChangeStartDate = (date) => {
    //     console.log(date, 'startDate')
    // }
    //
    // const onChangeEndDate = (date) => {
    //     console.log(date, 'endDate')
    //
    // }

    const handleFilter = (startDate, endDate) => {

        startDate = formatDate(startDate);
        endDate = formatDate(endDate);
        getEvents(startDate, endDate)
        console.log(startDate,endDate, 'startendDate')

    }



    const dateFormatted = (date) => {
        const formattedDate = moment(date).format('DD.MM.YYYY');
        return formattedDate
    }


    const navigateToPrivacyPolicy = () => {
        setShowSendReminderPopup(false)
        enableBodyScroll()
        navigate('/privacyPolicy');
    }
    return (
        <>

            <Helmet>
                <title>Календарь событий</title>
                <meta name="description" content="Календарь событий" />
            </Helmet>

            <div className='main_wrapper' id='events_calendar_page' style={dark_mode ? {backgroundColor: '#2A2C38'} : {}}>
                <Header/>
                <main style={dark_mode ? {backgroundColor: '#2A2C38'} : {}}>
                    <section className="events_calendar" style={dark_mode ? {borderTop: '1px solid #747474'} : {} }>
                        <div className="events_calendar_wrapper">
                            <div className='events_calendar_wrapper_child1'>
                                <div className='events_calendar_link_title_main_title_main_wrapper'>
                                    <div className="events_calendar_link_title_wrapper">
                                        <a href="" className="events_calendar_link" style={dark_mode ? {color: '#C6C9CF'} : {}}>Главная</a>
                                        <p className='events_calendar_link_icon' style={dark_mode ? {color: '#C6C9CF'} : {}}>·</p>
                                        <p className="events_calendar_link_title" style={dark_mode ? {color: '#C6C9CF'} : {}}>Календарь событий</p>
                                    </div>
                                    <div className="events_calendar_main_title_info_wrapper">
                                        <h1 className="events_calendar_main_title" style={dark_mode ? {color: '#ffffff'} : {}}>Календарь мероприятий</h1>
                                        <p className="events_calendar_info" style={dark_mode ? {color: '#ffffff'} : {}}>{events_total_count} событий</p>
                                    </div>
                                </div>

                                <div className="events_calendar_search_dropdowns_main_wrapper">
                                    <div className="events_calendar_box">


                                        {windowWidth <= 980 ? (
                                            <MobileCalendar handleFilter={handleFilter} />
                                        ) : (
                                            <Calendar handleFilter={handleFilter} />
                                        )}


                                    </div>
                                    <div className="calendar_mobile_line"></div>
                                    <div className="events_calendar_search_dropdowns_box">
                                        <div  className="events_calendar_search_box" style={dark_mode ? {backgroundColor: '#202333', border: '1px solid #4E4E4E'} : {}}>
                                            <input type="text" placeholder='Что будем искать?' value={event_search_input}
                                                   name='events_search' className={dark_mode ? 'events_calendar_search_input_field_dark' : 'events_calendar_search_input_field'}
                                                   onChange={(e) => {
                                                       setEventSearchInput(e.target.value)
                                                   }}
                                            />
                                            {/*<button className="events_calendar_search_btn">*/}
                                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">*/}
                                            {/*        <path d="M15.8505 14.4451C16.0498 14.6458 16.0498 14.9467 15.8505 15.1473L15.1526 15.8495C14.9533 16.0502 14.6542 16.0502 14.4548 15.8495L10.567 12.0376C9.47041 12.8401 8.07477 13.3417 6.67913 13.3417C2.99065 13.3417 0 10.3323 0 6.721C0 3.10972 2.99065 0 6.67913 0C10.3676 0 13.3583 3.0094 13.3583 6.721C13.3583 8.22571 12.8598 9.52978 12.0623 10.6332L15.8505 14.4451ZM6.67913 11.3354C9.27103 11.3354 11.3645 9.22884 11.3645 6.62069C11.3645 4.01254 9.17134 2.00627 6.67913 2.00627C4.18692 2.00627 1.99377 4.11285 1.99377 6.721C1.99377 9.32915 4.08723 11.3354 6.67913 11.3354Z" fill={dark_mode ? '#ffffff' : '#2F333A'}/>*/}
                                            {/*    </svg>*/}
                                            {/*</button>*/}
                                        </div>
                                        <div className="events_calendar_dropdown_wrapper" style={dark_mode ? {backgroundColor: '#1D1F22'} : {}}>
                                            <p className='events_calendar_dropdown_title'>Типы мероприятий</p>
                                            <div className='events_calendar_dropdown_btn_title_wrapper'
                                                 onClick={() => {
                                                     toggleEventsDropdown()
                                                 }}
                                            >
                                                <p
                                                    className='events_calendar_dropdown_btn_text'>
                                                    {select_all_events_list_option ? select_all_events_list_option.name : 'Выберите мероприятий'}
                                                </p>
                                                <button className='events_calendar_dropdown_btn'>
                                                    {show_all_events_dropdown ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 12 12" fill="none">
                                                            <path d="M6.0699 3.03984L1.31198 7.79776L2.4306 8.91797L6.0699 5.27629L9.7084 8.91797L10.8278 7.79855L6.0699 3.03984Z" fill="#E71E24"/>
                                                        </svg>
                                                        :
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                                                            <path d="M9.70043 14.6345L17.4633 6.87155L15.6382 5.04384L9.70043 10.9855L3.76392 5.04384L1.9375 6.87026L9.70043 14.6345Z" fill="#E71E24"/>
                                                        </svg>
                                                    }
                                                </button>
                                            </div>

                                            {show_all_events_dropdown &&
                                                 <div ref={dropdownRef} className="events_calendar_dropdown">
                                                {event_categories_list.map((option, index) => {
                                                    return (
                                                        <p
                                                            className="events_calendar_dropdown_info"
                                                            key={index}
                                                            onClick={() => selectAllEventsOption(option)}>
                                                            {option.name}
                                                        </p>
                                                    )
                                                })}


                                            </div>
                                            }

                                        </div>
                                        <div className="events_calendar_dropdown_wrapper"  style={dark_mode ? {backgroundColor: '#1D1F22'} : {}}>
                                            <p className='events_calendar_dropdown_title'>Организаторы</p>
                                            <div className='events_calendar_dropdown_btn_title_wrapper'
                                                 onClick={() => {
                                                      toggleOrganizersDropdown()
                                                 }}
                                            >
                                                <p
                                                    className='events_calendar_dropdown_btn_text'>
                                                    {select_all_organizers_list_option ? select_all_organizers_list_option.name : 'Выберите Организатору'}
                                                </p>
                                                <button className='events_calendar_dropdown_btn'>
                                                    {show_all_organizers_dropdown ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 12 12" fill="none">
                                                            <path d="M6.0699 3.03984L1.31198 7.79776L2.4306 8.91797L6.0699 5.27629L9.7084 8.91797L10.8278 7.79855L6.0699 3.03984Z" fill="#E71E24"/>
                                                        </svg>
                                                        :
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                                                            <path d="M9.70043 14.6345L17.4633 6.87155L15.6382 5.04384L9.70043 10.9855L3.76392 5.04384L1.9375 6.87026L9.70043 14.6345Z" fill="#E71E24"/>
                                                        </svg>
                                                    }

                                                </button>
                                            </div>

                                            {show_all_organizers_dropdown &&
                                            <div className="events_calendar_dropdown" ref={dropdownRef2} >
                                                {all_organizers_dropdown_list.map((option, index) => {
                                                    return (
                                                        <p
                                                            className="events_calendar_dropdown_info"
                                                            key={index}
                                                            onClick={() => selectAllOrganizersOption(option)}
                                                        >
                                                            {option.name}
                                                        </p>
                                                    )
                                                })}


                                            </div>
                                            }

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="events_calendar_wrapper_child2">
                                <div className='events_items_main_wrapper_parent' style={dark_mode ? {backgroundColor: '#1D1F22'} : {}}>
                                    <div className="events_items_main_wrapper">
                                        {events_list?.map((item, index, array) => {
                                            const isSubscribed = subscribedEvents.includes(item.id);

                                            let itemDateStart = item?.date_start ?  moment(item?.date_start).format('YYYY-MM') : undefined;
                                            let arrayDateStart = array[index - 1]?.date_start ? moment(array[index - 1]?.date_start).format('YYYY-MM') : undefined;
                                            console.log(subscribedEvents, 'subsEvents')
                                             console.log(itemDateStart, arrayDateStart, 'item.date_start !== array[index - 1].date_start')
                                        return (
                                            <div key={index}>
                                                { itemDateStart !== arrayDateStart ? (
                                                    <p className="events_items_main_title" style={dark_mode ? {color: '#ffffff'} : {}}>{getMonthName(item.date_start)}</p>
                                                ) : null}
                                                <div  key={index} className="events_item" style={dark_mode ? {backgroundColor: 'unset', border: '1px solid #747474'} : {}}>
                                                    <button className="events_item_img" onClick={() => navigateToEventsSinglePage(item?.id)}>
                                                        <img src={img_path + item?.logo} alt=""/>
                                                    </button>
                                                    <div className="events_item_info_box">
                                                        <button className="events_item_info_box_child" onClick={() => navigateToEventsSinglePage(item?.id)}>
                                                            <p className="events_item_info_box_title" style={dark_mode ? {color: '#C6C9CF'} : {}}>{item?.category?.name}</p>
                                                            {/*<p className="events_item_info_box_event_name">{item?.name}</p>*/}
                                                            <p className="events_item_info_box_event_name">{item?.title}</p>
                                                            <p className="events_item_info_box_info1" style={dark_mode ? {color: '#C6C9CF'} : {}}>{item?.short_description}</p>
                                                            <p className="events_item_info_box_title2" style={dark_mode ? {color: 'rgba(198, 201, 207, 0.60)'} : {}}>Организатор:</p>
                                                            <p className="events_item_info_box_info2" style={dark_mode ? {color: 'rgba(198, 201, 207, 0.60)'} : {}}>{item?.event_org[0]?.org?.name}</p>
                                                        </button>
                                                        <div className="events_item_info_box_child">
                                                            <div className="events_item_info_box_age_date_info_wrapper">
                                                                <p className="events_item_info_box_date_info">{dateFormatted(item.date_start)} - {dateFormatted(item.date_end)}</p>

                                                            </div>
                                                            <p className='events_item_days_until_completion_info' style={dark_mode ? {color: '#C6C9CF'} : {}}>{item.days_until_completion} дня до завершения</p>

                                                            {isSubscribed ?
                                                                <button className='event_send_a_reminder_btn'
                                                                        onClick={() => {
                                                                            cancelSubscribe(item?.id);
                                                                        }}
                                                                >
                                                                    Отменить подписку
                                                                </button>
                                                                :
                                                                <button type='button'
                                                                        className='event_send_a_reminder_btn'
                                                                        onClick={() => {
                                                                            openReminderPopup(item?.id, item?.title, item?.logo);
                                                                        }}
                                                                >
                                                                    Прислать напоминание
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    })}
                                    </div>
                                </div>
                                {nextDate != '' && events_list.length > 0 ?
                                    <div className='load_more_btn_pagination_wrapper' >
                                        <button className='load_more_events_btn' onClick={() => getEvents()}>
                                            Загрузить еще
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none"><rect x="6.83984" width="4" height="17" fill="white"/><rect x="17.3398" y="6.5" width="4" height="17" transform="rotate(90 17.3398 6.5)" fill="white"/></svg>
                                             </span>
                                        </button>
                                    </div>
                                    :
                                    <></>
                                }

                            </div>

                        </div>
                    </section>
                </main>
                {show_send_reminder_popup &&
                     <div className="show_send_reminder_popup">
                        <div className="show_send_reminder_popup_wrapper">
                            <div className='show_send_reminder_popup_wrapper_child'>
                            <div className='show_send_reminder_popup_title_close_btn_wrapper' style={dark_mode ? {borderBottom: '1px solid #747474'} : {}}>
                                <div className='show_send_reminder_popup_title_first_item'>
                                    <p className='show_send_reminder_popup_title1'>Прислать напоминание</p>
                                    <p className='show_send_reminder_popup_title2'>
                                        {event_title}
                                    </p>
                                </div>
                                <button
                                    className='show_send_reminder_popup_title_close_btn'
                                    onClick={() => {
                                        enableBodyScroll()
                                        setShowSendReminderPopup(false)
                                        setEmail('')
                                        setName('')
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                                        <path d="M28.3512 8.41675L19.5003 17.2676L10.6495 8.41675L8.41699 10.6492L17.2678 19.5001L8.41699 28.3509L10.6495 30.5834L19.5003 21.7326L28.3512 30.5834L30.5837 28.3509L21.7328 19.5001L30.5837 10.6492L28.3512 8.41675Z" fill="white"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="show_send_reminder_popup_form_img_wrapper">
                                <form action="" className="show_send_reminder_popup_form">
                                    <div className="show_send_reminder_popup_form_input_parent">
                                        <input type="text" placeholder='Имя' className={dark_mode ? 'show_send_reminder_popup_form_input_field_dark' : 'show_send_reminder_popup_form_input_field'} value={name}
                                               onChange={(e) => {
                                                   setName(e.target.value)
                                               }}
                                        />
                                        {name_error &&
                                            <p className='error_text3'>{name_error_text}</p>
                                        }
                                    </div>
                                    <div className="show_send_reminder_popup_form_input_parent">
                                        <input type="text" placeholder='E-mail'  className={dark_mode ? 'show_send_reminder_popup_form_input_field_dark' : 'show_send_reminder_popup_form_input_field'} value={email}
                                               onChange={(e) => {
                                                   setEmail(e.target.value)
                                               }}
                                        />
                                        {email_error &&
                                            <p className='error_text3'>{email_error_text}</p>
                                        }
                                    </div>
                                    <p className='show_send_reminder_popup_info'>
                                        Нажимая кнопку "Отправить", я подтверждаю, что ознакомлен
                                        и согласен с условиями <button onClick={() => navigateToPrivacyPolicy()} className='show_send_reminder_popup_info_link'>политики обработки персональных данных</button>
                                    </p>
                                    <button type='button' className='show_send_reminder_popup_form_btn' onClick={() => getSubscribe(eventId)}>
                                        Напомнить
                                    </button>
                                </form>
                                <div className="show_send_reminder_popup_form_img">
                                    <img src={img_path + popup_img} alt=""/>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                }
                {show_success_popup &&
                    <div className="show_send_reminder_popup">
                    <div className="show_send_reminder_popup_wrapper">
                        <div className='show_send_reminder_popup_wrapper_child'>
                            <div className='show_send_reminder_popup_title_close_btn_wrapper show_send_reminder_popup_title_close_btn_wrapper2' style={dark_mode ? {borderBottom: '1px solid #747474'} : {}}>
                                <button
                                    className='show_send_reminder_popup_title_close_btn'
                                    onClick={() => {
                                        enableBodyScroll()
                                        setShowSuccessPopup(false)
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                                        <path d="M28.3512 8.41675L19.5003 17.2676L10.6495 8.41675L8.41699 10.6492L17.2678 19.5001L8.41699 28.3509L10.6495 30.5834L19.5003 21.7326L28.3512 30.5834L30.5837 28.3509L21.7328 19.5001L30.5837 10.6492L28.3512 8.41675Z" fill="white"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="show_send_reminder_popup_form_img_wrapper">
                                <p className='show_send_reminder_popup_title1'>Заявка успешно отправленна</p>
                                <div className="show_send_reminder_popup_form_img">
                                    <img src={img_path + popup_img} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <Footer/>
            </div>

        </>

    )


}



