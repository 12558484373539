import React, {useEffect, useState} from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";


const CalendarMobile = (props) => {

    // const [startDate, setStartDate] = useState(new Date());
    // const [endDate, setEndDate] = useState(() => {
    //     const nextMonth = new Date();
    //     nextMonth.setMonth(nextMonth.getMonth() + 1);
    //     return nextMonth;
    // });

    const [events, setEvents] = useState([]);
    const [showSelectedDates, setShowSelectedDates] = useState(false);
    const [dark_mode, setDarkMode] = useState(false);

    useEffect(() => {

        let darkMode = localStorage.getItem('darkMode')
        if (darkMode) {
            setDarkMode(true)
        }

    }, [])


    const [selectedDayRange, setSelectedDayRange] = useState(()=>{
        console.log(new Date().getDay() + 5, 'dateeeeeeeeeeeeeeeeeeeeeeeeeeeee')

        const defaultFrom = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate(),
        };
        const defaultTo = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate() ,
        };


        return {
            from: defaultFrom,
            to: defaultTo,
        }
    });

    useEffect(() => {

        if (selectedDayRange.from && selectedDayRange.to) {

        }
        console.log(selectedDayRange, 'selectedDayRange')
    }, [selectedDayRange])

    const handleDisabledSelect = disabledDay => {
        console.log('Tried including a disabled day', disabledDay);
    };

    const handleAddEvent = () => {
        if (!selectedDayRange.from || !selectedDayRange.to) {
            alert('Выберите дату начала и дату конца')
        }

        setShowSelectedDates(true); // Set the flag to show selected dates

        let from = new Date(selectedDayRange?.from?.year, selectedDayRange?.from?.month - 1, selectedDayRange?.from?.day);
        let to = new Date(selectedDayRange?.to?.year, selectedDayRange?.to?.month - 1, selectedDayRange?.to?.day);

        props.handleFilter(from, to)
    };

    const formatRussianDate = (date) => {

        const customDate = new Date(date.year, date.month-1, date.day);

        return customDate.toLocaleDateString('ru-RU', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    };

    return (

    <div className='calendar_main_wrapper_parent'>
        <div className='calendar_main_wrapper'>
            <div className='calendar_child'>
                <Calendar
                    value={selectedDayRange}
                    onChange={setSelectedDayRange}
                    // disabledDays={disabledDays} // here we pass them
                    onDisabledDayError={handleDisabledSelect} // handle error
                    colorPrimary="#E71E24" // added this
                    colorPrimaryLight="#E71E2420"
                    shouldHighlightWeekends
                    calendarClassName="custom-today-day"
                />
            </div>
        </div>

        {selectedDayRange.from && selectedDayRange.to && (
            <p className='shown_dates' style={dark_mode ? {color: '#ffffff'} : {}}>
                {formatRussianDate(selectedDayRange.from)} - {formatRussianDate(selectedDayRange.to)}
            </p>
        )}

        <button onClick={handleAddEvent} className='calendar_show_btn'>
            Показать
        </button>
    </div>
    );
};

export default CalendarMobile;
