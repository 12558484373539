import React, { useEffect, useState } from 'react';
import "../../assets/css/loader.css"

const App = () => {
    const [accessToken, setAccessToken] = useState(null);
    const [tokenType, setTokenType] = useState(null);
    const [expiresIn, setExpiresIn] = useState(null);

    useEffect(() => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.hash.substring(1));

        const accessToken = params.get('access_token');
        const tokenType = params.get('token_type');
        const expiresIn = params.get('expires_in');

        setAccessToken(accessToken);
        setTokenType(tokenType);
        setExpiresIn(expiresIn);

        if (accessToken) {
            let myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${accessToken}`);

            let requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch("https://login.yandex.ru/info?&format=json", requestOptions)
                .then(response => response.json())
                .then(result =>
                    {
                        console.log(result, 'yandexResult')

                        if (result?.emails[0]) {
                            let formdata = new FormData();
                            formdata.append("soc_network_id", "0aa476668a76442d89393c4e86b301f8");
                            formdata.append("type", "yd");
                            formdata.append("email", result?.emails[0]);

                            let requestOptions = {
                                method: 'POST',
                                body: formdata,
                                redirect: 'follow'
                            };

                            fetch("https://smus.justcode.am/api/login_or_register_with_soc_network", requestOptions)
                                .then(response => response.json())
                                .then(result =>
                                    {
                                        console.log(result, 'loginWithYandex')
                                        if (result?.status === true) {
                                            localStorage.setItem('token', result?.token)
                                            window.location='/personalArea';
                                        }
                                    }

                                )
                                .catch(error => console.log('error', error));
                        }
                    }

                )
                .catch(error => console.log('error', error));
        }

        //
        // const script = document.createElement('script');
        // script.src = 'https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-token-with-polyfills-latest.js';
        // script.async = true;
        // script.onload = () => {
        //     window.YaSendSuggestToken("https://examplesite.com", {
        //         "accessToken": accessToken
        //         "tokenType": tokenType
        //         "expiresIn": expiresIn
        //     });
        //     console.log('Токен отправлен успешно');
        // };
        // script.onerror = (error) => {
        //     console.error('Произошла ошибка при загрузке скрипта:', error);
        // };
        // document.head.appendChild(script);

    }, []);

    return (

        <div className="loader-container">
            <div className="loader"></div>
        </div>
    );
};

export default App;
